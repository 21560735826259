.nkab-navbar-main{
  background-color: #dbdbdb33;
}
.nav-l{
  background: #dbdbdb33 !important;
}
.nav-t{
  background: #0E233A !important;
}
.color-l{
  color: #0a146e !important;
}
.color-t{
  color: #90d9ff !important;
}
.nav-link{
  color: #0a146e !important;
  font-size: 15px !important;
  font-weight: 500 !important;
}
.nav-link:hover{
  color: #c7b301 !important;
}
.nav-link-t{
  color: #90d9ff !important;
  font-size: 15px !important;
  font-weight: 500 !important;
}
.nav-link-t:hover{
  color: #c7b301 !important;
}
/* .nav-container{
  background: #dbdbdb09;
} */
.nkab-lite-switch{
  background-color: #dbdbdbad;
  border-radius: 50px;
  padding: 0 5px 0 5px;
}
.nkab-notif-content{
    position: absolute;
    height: 30rem;
    width: 33rem;
    margin-left: 80rem;
    margin-top: 0rem;
    background-color: #f0f0f0;
    box-shadow: 05px 2px 30px rgba(0, 0, 0, 0.247);
    border-radius: 20px;
    padding: 10px;
    overflow-y: auto;
    z-index: 1;
}
.nkab-theme-mobile{
  display: none;
}
.css-1x7skt0{
  box-shadow: none !important;
}
.css-x3w4s2-MuiBadge-badge{
  background-color: #be0202;
}
.css-hip9hq-MuiPaper-root-MuiAppBar-root, .css-1qnavfx-MuiPaper-root-MuiAppBar-root{
  box-shadow: none !important;
}
.nkab-notif-item{
    background-color: rgba(225, 225, 225, 0.418);
    box-shadow: 05px 2px 20px rgba(0, 0, 0, 0.247);
    border-radius: 20px;
    margin-bottom: 5px;
}
.nkab-notif-item:hover{
    background-color: rgb(217, 236, 253);
}
.nkab-notif-img{
    border-right: 0.5px solid #89898a8a;
    padding: 10px;
}
.nkab-notif-description a{
    text-decoration: none;
    color: rgb(61, 61, 61);
}
.nkab-notif-option{
    padding-top: 27px;
    padding-right: 20px;
}
.nkab-notif-trash{
    color: rgb(87, 87, 87);
}
.nkab-notif-trash:hover{
    cursor: pointer;
    color: rgb(163, 0, 0);
}
.pro-wallet{
    color: #c7b301;
    border-radius: 50px;
    padding: 0 10px 0 10px;
}
.pro-wallet:hover{
    color: #fff;
    background-color: #c7b301;
    cursor: pointer;
}
.pro-wallet-btn{
    background-color: #c7b301;
    padding: 0 10px 0 10px !important;
    color: black !important;
    border-radius: 50px;
    font-weight: bolder !important;
}
.pro-wallet-btn:hover{
    background-color: #fdb164;
    color: #fff !important;
    cursor: pointer;
}

.nkp_logo {
	height: 40px;
  border-radius: 5px;
}

.pro-style{
    border: 2px solid #be0202;
    background-color: #fff;
    color: #be0202;
    border-radius: 50px;
    padding: 3px 12px;
    font-weight: 500;
    transition: 3ms;
}
.nkp__v_pro-switch{
  padding-top: 9px;
  color: #c7b301;
}
.pro-style:hover{
  background-color: #be0202;
  color: #fff !important;
  border: 2px solid #fff;
}

.nkab-notif-icon {
   color: #be0202 !important;
  }

.nkab-color-point-option{
    color: #dddddd;
    background-color: #89898a8a;
    border-radius: 20px;
    padding-left: 5px;
    padding-right: 5px;
    font-weight: 500;
    font-size: smaller;
}
.nkab-color-point-option:hover{
    color: #0a146e;
    background-color: #ededee8a;
}
.nkab-color-pointed1ov{
    color: #dddddd;
    background-color: #0b1b30 !important;
    border-radius: 20px;
    padding-left: 5px;
    padding-right: 5px;
    font-weight: 500;
    font-size: smaller;
}
.nkab-color-pointed2ov{
    color: #0a146e;
    background-color: rgb(222, 235, 240) !important;
    border-radius: 20px;
    padding-left: 5px;
    padding-right: 5px;
    font-weight: 500;
    font-size: smaller;
}

.nkab-color-pointed1ov:hover{
    border: 2px solid #c7b301;
}
.nkab-color-pointed2ov:hover{
    border: 2px solid #c7b301;
}
.hovered{
    border: 2px solid #c7b301;
    margin-right: 5px;
}






.toggle {
    cursor: pointer;
    display: inline-block;
  }
  
  .toggle-switch {
    display: inline-block;
    background: #0b1b30;
    border-radius: 16px;
    width: 51px;
    height: 25px;
    position: relative;
    vertical-align: middle;
    transition: background 0.25s;
  }
  .toggle-switch:before, .toggle-switch:after {
    content: "";
  }
  .toggle-switch:before {
    display: block;
    background: linear-gradient(to bottom, #fff 0%, #eee 100%);
    border-radius: 50%;
    box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.25);
    width: 18px;
    height: 18px;
    position: absolute;
    top: 4px;
    left: 4px;
    transition: left 0.25s;
  }
  .toggle:hover .toggle-switch:before {
    background: linear-gradient(to bottom, #fff 0%, #fff 100%);
    box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.5);
  }
  .toggle-checkbox:checked + .toggle-switch {
    background: rgb(170, 193, 202);
  }
  .toggle-checkbox:checked + .toggle-switch:before {
    left: 30px;
  }
  
  .toggle-checkbox {
    position: absolute;
    visibility: hidden;
  }
  
  .toggle-label {
    margin-left: 5px;
    position: relative;
    top: 2px;
  }
  .css-1qnavfx-MuiPaper-root-MuiAppBar-root{
    box-shadow: none !important;
  }


@media screen and (max-width: 767px) {
    .nkab-notif-content{
        height: 20rem;
        width: 31.3rem;
        margin-left: -1rem;
        margin-top: 0.3rem;
        background-color: #f0f0f0;
        box-shadow: 05px 2px 30px rgba(0, 0, 0, 0.247);
    }
    .nkab-notif-img{
        padding: 10px;
    }
    .nkab-notif-description{
        font-size: 10px;
    }
    .nkp__v_lite-switch{
      display: none;
    }
  }