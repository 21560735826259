.st-body{
    margin: 0;
    padding: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 100vh;
    font-family: 'Jost', sans-serif;
}
.st-main{
    width: 350px;
    height: 627px;
    overflow: hidden;
    border-radius: 20px;
    box-shadow: 5px 20px 50px #0000003d;
}

#st-chk{
    display: none;
}
.st-signup{
    position: relative;
    width: 100%;
    height: 100%;
}
.st-label{
    color: #fff;
    font-size: 2em;
    justify-content: center;
    display: flex;
    margin: 50px auto;
    font-weight: bold;
    cursor: pointer;
    transition: .5s ease-in-out;
}
.st-input[type="text"]{
    width: 60%;
    height: 30px;
    color: rgb(54, 54, 54);
    background: #e0dede;
    justify-content: center;
    display: flex;
    margin: 20px auto;
    padding: 10px;
    border: none;
    outline: none;
    border-radius: 5px;
}
.st-input[type="tel"]{
    width: 60%;
    height: 30px;
    color: rgb(54, 54, 54);
    background: #e0dede;
    justify-content: center;
    display: flex;
    margin: 20px auto;
    padding: 10px;
    border: none;
    outline: none;
    border-radius: 5px;
}
.st-input[type="email"]{
    width: 60%;
    height: 30px;
    color: rgb(54, 54, 54);
    background: #e0dede;
    justify-content: center;
    display: flex;
    margin: 20px auto;
    padding: 10px;
    border: none;
    outline: none;
    border-radius: 5px;
}
.st-input[type="password"]{
    width: 60%;
    height: 30px;
    color: rgb(54, 54, 54);
    background: #e0dede;
    justify-content: center;
    display: flex;
    margin: 20px auto;
    padding: 10px;
    border: none;
    outline: none;
    border-radius: 5px;
}
.st-country{
    display: flex;
    justify-content: center;
}
.st-button{
    width: 60%;
    margin: 10px auto;
    justify-content: center;
    display: block;
    color: #fff;
    border-radius: 5px;
    font-size: 1em;
    font-weight: bold;
    margin-top: 20px;
    outline: none;
    border: none;
    transition: .2s ease-in;
    cursor: pointer;
}
.st-button:hover{
    color: #ff8000;
}

.st-login{
    height: 520px;
    background: #eee;
    border-radius: 60px 60px 0px 0px;
    transform: translateY(-180px);
    transition: .8s ease-in-out;
}
.st-login .st-label{
    color: #ff8000;
    transform: scale(.6);
}
#st-chk:checked ~ .st-login{
    transform: translateY(-600px);
}
#st-chk:checked ~ .st-login .st-label{
    transform: scale(1);
}
#st-chk:checked ~ .st-signup .st-label{
    transform: scale(.6);
}