/* section form  login*/
.container .signin-session .logo-session {
    margin: auto;
    background-color: #0a146e;
    border-top-right-radius: 30px;
    border-top-left-radius: 30px;
    border-bottom: #c7b301 8px solid;
  }
  
  .container .signin-session .logo-session img {
    width: 163px !important;
    height: 120px;
    margin: 0 100px;
    text-align: center;
  }
  
  .container .signin-session .form {
    border-bottom-right-radius: 30px;
    border-bottom-left-radius: 30px;
    border-bottom: #c7b301 8px solid;
    border-top-right-radius: none;
    border-top-left-radius: none;
  }
  
  /* section form forgetpassword */
  .container .forgot-password {
    margin: 10px 0px 100px 0px;
  }
  
  .container .forgot-password .card {
    background-color: #f7f7f7;
    border: none;
    border-radius: 20px;
    border-top: #0a146e 8px solid;
    border-bottom: #c7b301 8px solid;
  }
  
  .container .forgot-password .card .card-body h3 i {
    color: #0a146e;
    font-weight: bolder;
    border: #0a146e 2px solid;
    padding: 20px;
    border-radius: 20px;
    font-size: 35px;
  }
  
  .container .forgot-password .card .form-control {
    border: none;
    border-radius: 15px;
  
  }
  
  /* section form resetpassword */
  .container .resetpassword {
    margin: 10px 0px 100px 0px;
  }
  
  
  .container .resetpassword .card {
    background-color: #64d0ebad;
    border: none;
    border-radius: 20px;
    border-top: #0a146e 8px solid;
    border-bottom: #c7b301 8px solid;
  }
  
  .container .resetpassword .card .card-body h3 i {
    color: #0a146e;
    font-weight: bolder;
    border: #0a146e 2px solid;
    padding: 20px;
    border-radius: 20px;
  }
  
  .container .resetpassword .card .form-control {
    border: none;
    border-radius: 15px;
  
  }