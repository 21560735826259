.nkp-op-btn{
    background-color: #c7b301;
    padding: 5px 10px 5px 10px;
    border-radius: 50px;
    color: #fff;
    font-weight: 600;
}
.nkp-op-btn:hover{
    background-color: #fca34b;
    color: #233f8e;
    cursor: pointer;
}