.nkab-charge-container-t{
    border-radius: 20px;
    border: 1px solid #8ad8ff56;
    height: 100%;
    width: 75%;
    color: #90d9ff !important;
}
.nkab-charge-container{
  border-radius: 20px;
  border: 1px solid #0a146e;
  height: 100%;
  width: 75%;
  color: #0a146e !important;
}
.nkab-r-text-t{
    font-style: italic;
    font-family: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
    background: #8ad8ff56;
    border-radius: 20px 20px 0px 0px;
}
.nkab-r-text{
    font-style: italic;
    font-family: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
    background: #c4c6c756;
    border-radius: 20px 20px 0px 0px;
}
.nkab-r-title-t{
    background: #8ad8ff49;
    border-radius: 20px;
    padding: 3px 7px 0px 7px;
}
.nkab-r-title-t:hover{
    cursor: pointer;
    background: #8ad8ff75;
}
.nkab-r-title{
    background: #c4c6c7e3;
    border-radius: 20px;
    padding: 3px 7px 0px 7px;
}
.nkab-r-title:hover{
    cursor: pointer;
    background: #c4c6c756;
}
.nkab-dote-menu-t{
    background: #8ad8ff49;
    border-radius: 50px;
    padding: 0px 7px 6px 7px;
}
.nkab-dote-menu-t:hover{
    background: #8ad8ff75;
    cursor: pointer;
}
.nkab-dote-menu{
    background: #c4c6c7e3;
    border-radius: 50px;
    padding: 0px 7px 6px 7px;
}
.nkab-dote-menu:hover{
    background: #c4c6c756;
    cursor: pointer;
}
.nkab-r-btn{
    font-style: italic;
    font-family: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
    background: #8ad8ff49;
    border-radius: 20px;
    padding: 3px 7px 3px 7px;
}
.nkab-r-btn:hover{
    cursor: pointer;
    background: #8ad8ff75;
}
.nkab-bank-table{
  overflow-x: auto !important;
}




.svg {
    width: 100px;
    display: block;
    margin: 40px auto 0;
  }
  
  .path {
    stroke-dasharray: 1000;
    stroke-dashoffset: 0;
    &.circle {
      -webkit-animation: dash .9s ease-in-out;
      animation: dash .9s ease-in-out;
    }
    &.line {
      stroke-dashoffset: 1000;
      -webkit-animation: dash .9s .35s ease-in-out forwards;
      animation: dash .9s .35s ease-in-out forwards;
    }
    &.check {
      stroke-dashoffset: -100;
      -webkit-animation: dash-check .9s .35s ease-in-out forwards;
      animation: dash-check .9s .35s ease-in-out forwards;
    }
  }
  
  .p {
    text-align: center;
    margin: 20px 0 60px;
    font-size: 1.25em;
    &.success {
      color: #73AF55;
    }
    &.error {
      color: #D06079;
    }
  }
  
  
  @-webkit-keyframes dash {
    0% {
      stroke-dashoffset: 1000;
    }
    100% {
      stroke-dashoffset: 0;
    }
  }
  
  @keyframes dash {
    0% {
      stroke-dashoffset: 1000;
    }
    100% {
      stroke-dashoffset: 0;
    }
  }
  
  @-webkit-keyframes dash-check {
    0% {
      stroke-dashoffset: -100;
    }
    100% {
      stroke-dashoffset: 900;
    }
  }
  
  @keyframes dash-check {
    0% {
      stroke-dashoffset: -100;
    }
    100% {
      stroke-dashoffset: 900;
    }
  }