/* section form  login*/

.container .signin-session .logo-session {
    margin: auto;
    background-color: #03036b;
    border-top-right-radius: 30px;
    border-top-left-radius: 30px;
    border-bottom: #c7b301 8px solid;
  }
  
  .container .signin-session .logo-session img {
    width: 120px;
    height: 120px;
    margin: 0 100px;
    text-align: center;
  }
  
  .container .signin-session .form {
    border-bottom-right-radius: 30px;
    border-bottom-left-radius: 30px;
    border-bottom: #c7b301 8px solid;
    border-top-right-radius: none;
    border-top-left-radius: none;
  }
  
  /* section form forgetpassword */
  .container .forgot-password {
    margin: 100px 0px 100px 0px;
    backdrop-filter: blur(14px) !important;
    box-shadow: 0 15px 25px rgba(129, 124, 124, 0.2);
    border-radius: 20px;
  }
  
  .container .forgot-password .card {
    /* background-color: #f7f7f7; */
    border: none;
    border-radius: 20px;
    border-top: #03037e 8px solid;
    border-bottom: #c7b301 8px solid;
  }
  
  .container .forgot-password .card .card-body h3 i {
    color: #03037e;
    font-weight: bolder;
    border: #03037e 2px solid;
    padding: 20px;
    border-radius: 20px;
    font-size: 35px;
  }
  
  .container .forgot-password .card .form-control {
    border: none;
    border-radius: 15px;
  
  }
  
  /* section form resetpassword */
  .container .resetpassword {
    margin: 10px 0px 100px 0px;
  }
  
  
  .container .resetpassword .card {
    background-color: #64d0ebad;
    border: none;
    border-radius: 20px;
    border-top: #03037e 8px solid;
    border-bottom: #c7b301 8px solid;
  }
  
  .container .resetpassword .card .card-body h3 i {
    color: #03037e;
    font-weight: bolder;
    border: #03037e 2px solid;
    padding: 20px;
    border-radius: 20px;
  }
  
  .container .resetpassword .card .form-control {
    border: none;
    border-radius: 15px;
  
  }
  .nkab-ciblage-btn{
    color: #ffffff;
    padding: 0px 5px 0px 5px;
    border-radius: 50px;
    background-color: #ffd0936e;
  }
  .nkab-ciblage-btn:hover{
    background-color: #ffd093;
    color: #464647;
  }
  .mailing-body-table {
    overflow: auto;
    max-height: 30vh;
    font-family: system-ui, -apple-system, BlinkMacSystemFont, "Avenir Next", "Avenir", "Segoe UI", "Lucida Grande", "Helvetica Neue", "Helvetica", "Fira Sans", "Roboto", "Noto", "Droid Sans", "Cantarell", "Oxygen", "Ubuntu", "Franklin Gothic Medium", "Century Gothic", "Liberation Sans", sans-serif;
    color: #bce8ff !important;
    background-color: #03234e;
  }

  .nkab-checked-hover{
    background-color: #000000b4;
    border-radius: 50px;
    padding: 3px;
  }

  .nkab-hover:hover{
    background-color: #ffd093;
    border-radius: 50px;
    padding: 3px;
  }

  @media (min-width: 48em) {
    .mailing-body-table {
      margin: 2%;
    }
  }
  @media (min-width: 75em) {
    .mailing-body-table {
      margin: 1em auto;
      max-width: 95em;
    }
  }

  .mailing-responsive-table {
    width: 100%;
    margin-bottom: 1.5em;
    border-spacing: 0;
    color: #bce8ff !important;
  }
  
  @media (min-width: 48em) {
    .mailing-responsive-table {
      font-size: 0.9em;
    }
  }
  @media (min-width: 62em) {
    .mailing-responsive-table {
      font-size: 1em;
    }
  }
  .mailing-responsive-table thead {
    position: absolute;
    clip: rect(1px 1px 1px 1px);
    /* IE6, IE7 */
    padding: 0;
    border: 0;
    height: 1px;
    width: 1px;
    overflow: hidden;
  }
  @media (min-width: 48em) {
    .mailing-responsive-table thead {
      position: relative;
      clip: auto;
      height: auto;
      width: auto;
      overflow: auto;
    }
  }
  .mailing-responsive-table thead th {
    background-color: #021125;
    border: 1px solid #021125;
    font-weight: bold;
    text-align: center;
    color: #bce8ff
  }
  .mailing-responsive-table thead th:first-of-type {
    text-align: left;
  }
  .mailing-responsive-table tbody,
  .mailing-responsive-table tr,
  .mailing-responsive-table th,
  .mailing-responsive-table td {
    display: block;
    padding: 0;
    text-align: left;
    white-space: normal;
  }
  @media (min-width: 48em) {
    .mailing-responsive-table tr {
      display: table-row;
    }
  }
  .mailing-responsive-table th,
  .mailing-responsive-table td {
    padding: 0.5em;
    vertical-align: middle;
  }
  @media (min-width: 30em) {
    .mailing-responsive-table th,
  .mailing-responsive-table td {
      padding: 0.75em 0.5em;
    }
  }
  @media (min-width: 48em) {
    .mailing-responsive-table th,
  .mailing-responsive-table td {
      display: table-cell;
      padding: 0.5em;
    }
  }
  @media (min-width: 62em) {
    .mailing-responsive-table th,
  .mailing-responsive-table td {
      padding: 0.75em 0.5em;
    }
  }
  .mailing-responsive-table caption {
    margin-bottom: 1em;
    font-size: 1em;
    font-weight: bold;
    text-align: center;
  }
  @media (min-width: 48em) {
    .mailing-responsive-table caption {
      font-size: 1.5em;
    }
  }
  .mailing-responsive-table tfoot {
    font-size: 0.8em;
    font-style: italic;
  }
  @media (min-width: 62em) {
    .mailing-responsive-table tfoot {
      font-size: 0.9em;
    }
  }
  @media (min-width: 48em) {
    .mailing-responsive-table tbody {
      display: table-row-group;
    }
  }
  .mailing-responsive-table tbody tr {
    margin-bottom: 1em;
  }
  @media (min-width: 48em) {
    .mailing-responsive-table tbody tr {
      display: table-row;
      border-width: 1px;
    }
  }
  .mailing-responsive-table tbody tr:last-of-type {
    margin-bottom: 0;
  }
  @media (min-width: 48em) {
    .mailing-responsive-table tbody tr:nth-of-type(even) {
      background-color: rgba(0, 0, 0, 0.12);
    }
  }
  .mailing-responsive-table tbody th[scope=row] {
    background-color: #021125;
    color: #bce8ff;
  }
  @media (min-width: 30em) {
    .mailing-responsive-table tbody th[scope=row] {
      border-left: 1px solid #021125;
      border-bottom: 1px solid #021125;
    }
  }
  @media (min-width: 48em) {
    .mailing-responsive-table tbody th[scope=row] {
      background-color: transparent;
      color: #bce8ff !important;
      text-align: left;
    }
  }
  .mailing-responsive-table tbody td {
    text-align: right;
  }
  @media (min-width: 48em) {
    .mailing-responsive-table tbody td {
      border-left: 1px solid #021125;
      border-bottom: 1px solid #021125;
      text-align: center;
    }
  }
  @media (min-width: 48em) {
    .mailing-responsive-table tbody td:last-of-type {
      border-right: 1px solid #021125;
    }
  }
  .mailing-responsive-table tbody td[data-type=currency] {
    text-align: right;
  }
  .mailing-responsive-table tbody td[data-title]:before {
    content: attr(data-title);
    float: left;
    font-size: 0.8em;
    color: #bce8ff !important;
  }
  @media (min-width: 30em) {
    .mailing-responsive-table tbody td[data-title]:before {
      font-size: 0.9em;
    }
  }
  @media (min-width: 48em) {
    .mailing-responsive-table tbody td[data-title]:before {
      content: none;
    }
  }









