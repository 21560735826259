.nkab-exchange-banner{
    width: 1000px;
    border-radius: 20px;
}

@media (max-width:767px) { 
  .nkab-exchange-banner {
    width: 100%;
  }
}


.nkab--catalog__container p {
  color: #fff;
  font-size: 16px;
  font-family: "ProximaNova-Regular", Arial, Helvetica, sans-serif !important;
  margin-bottom: 16px;
}

.nkab--catalog__copy {
  display: none;
  padding: 12px 16px;
  background-color: #00446a;
  text-align: center;
  box-shadow: 0 -7px 5px -5px rgba(0, 0, 0, 0.08);
}
@media (min-width: 1024px) {
  .nkab--catalog__container p {
    margin-bottom: 0;
  }

  .nkab--catalog__copy {
    display: block;
  }
}