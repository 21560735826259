 /* Cette feuille style est destinée à deux fichiers js NewserviceScreen et NewOperatorScreen */

 /*pour NewserviceScreen */
 .monnaie {
    max-width: 1000vh;
    margin: auto;
    background-color: #e8eaf6;
}

/*pour NewserviceScreen */
.operateur {
    max-width: 1000vh;
    margin: auto;

}

.box-operateur {
    margin-top: 15px;
    padding: 15px 10px;
    background-color: #c7b301;
    border-radius: 20px
}
.box-operateur .img-nkab img {
   width: 80px;
   height: 80px;
   border-radius: 50%;
}
.box-operateur .col-md-4 {
   text-align: center;
}

.bg-operateur {
   background-color: #ee969b;
   color: #af0505;
   padding: 3px 0;
   display: inline;
   border-radius: 25px;
   font-size: 11px
}
.p-white {
   font-size: 14px;
   color: #150347;
}

/* find*/


/* Tous sont liés */
.box-right {
    margin-top: 15px;
    padding: 15px 10px;
    background-color: white;
    border-radius: 20px
}

.box-right .col-md-4 {
    text-align: center;
}

.box-right .img-nkab img {
    width: 80px;
    height: 80px;
    border-radius: 50%;
}


.textmuted {
    color: #7a7a7a
}

.bg-green {
    background-color: #d4f8f2;
    color: #06e67a;
    padding: 3px 0;
    display: inline;
    border-radius: 25px;
    font-size: 11px
}

.p-blue {
    font-size: 14px;
    color: #1976d2
}

.fas.fa-circle {
    font-size: 12px
}

.p-org {
    font-size: 14px;
    color: #fbc02d
}

.h7 {
    font-size: 15px
}

.h8 {
    font-size: 12px
}

.h9 {
    font-size: 10px
}

.bg-blue {
    background-color: #dfe9fc9c;
    border-radius: 5px
}

input.form-control {
    box-shadow: none !important;
    border-bottom: 2px solid #150347;
    border-radius: 1px;
    padding: 20px;
    color: #0e0d0d;

}

.card input::placeholder {
    font-size: 14px
}

::placeholder {
    font-size: 14px
}

input.card {
    position: relative;
}

.far.fa-credit-card {
    position: absolute;
    top: 10px;
    padding: 0 15px
}

.fas,
.far {
    cursor: pointer;
}

.cursor {
    cursor: pointer;
}

.btn.btn-primary {
    box-shadow: none;
    height: 40px;
    padding: 11px;
}

.bg.btn.btn-primary {
    background-color: transparent;
    border: none;
    color: #1976d2;
}

.bg.btn.btn-primary:hover {
    color: white;
    border: 1px solid #539ee9;
}

@media(max-width:320px) {
    .h8 {
        font-size: 11px;
    }

    .h7 {
        font-size: 13px;
    }

    ::placeholder {
        font-size: 10px;
    }
}