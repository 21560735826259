#nkab-popup { 
    position:fixed;
    visibility:hidden; 
    opacity:0; 
    margin-top:-300px; 
}

#nkab-popup:target { 
    visibility:visible; 
    opacity:1; 
    background-color:rgba(255,255,255,0.7); 
    position:fixed; 
    top:0; 
    left:0; 
    right:0; 
    bottom:0; 
    margin:0; 
    z-index:99999999999; 
    -webkit-transition:all 1s; 
    -moz-transition:all 1s; 
    transition:all 1s; 
}
.nkab-up-img{
    width: 400px;
}

@media (min-width:768px) { 
    .nkab-popup-container { width:600px; } 
    .nkab-up-img{
        width: 250px;
    }

}
@media (max-width:767px) { 
    .nkab-popup-container { width:100%; } 
    .nkab-up-img{
        width: 250px;
    }
}

.nkab-popup-container { 
    position:relative; 
    margin:7% auto; 
    padding:30px 50px; 
    background-color:#333;
    color:#fff; 
    border-radius:10px; 
}

a.nkab-popup-close { 
    position:absolute; 
    top:3px; 
    right:3px; 
    background-color:#333; 
    padding:7px 10px; 
    font-size:20px; 
    text-decoration:none; 
    line-height:1; 
    color:#fff; 
}










:root{
    --color-1:#f68121;
    --color-2:#ed1c24;
    --color-3:#582c8b;
    --color-4:#0166b4;
}
.operations-page-wrapper{
    padding: 100px 0;
}
.operations-infographic-cards {
    width: 100%;
    position: relative;
    display: flex;
    gap: 2%;
    justify-content: center;
    padding-left: 0;

}
.operations-infographic-cards li {
    list-style: none;
    width: calc(100% /4);
    height: 250px;
    padding: 25px 30px;
    background: #eee;
    position: relative;
    background-image: linear-gradient(to bottom right, #e9eaecda, #ffffff);
    filter: drop-shadow(10px 10px 10px rgba(0, 0, 0, 0.25));
    box-shadow: inset 2px 2px 2px white, inset -1px -1px 1px rgba(0, 0, 0, 0.25);
    text-align: center;
    display: inline-block;
    margin-bottom: 25px;
    border-radius: 20px;
}
.operations-infographic-cards li h5{
    font-size: 25px;
    font-weight: 800;
    margin-bottom: 15px;
  }
 .operations-infographic-cards li h6{
    font-size: 14px;
    font-weight: 400;
    margin-bottom: 0;
  }
 .operations-infographic-cards li.color-1{
    border: 10px solid var(--color-1);
  }
  
 .operations-infographic-cards li.color-2{
    border: 10px solid var(--color-2);
  }
  
 .operations-infographic-cards li.color-3{
    border: 10px solid var(--color-3);
  }
  
 .operations-infographic-cards li.color-4{
    border: 10px solid var(--color-4);
  }
  

  .operations-infographic-cards li i{
    font-size: 35px;
    margin-bottom: 15px;
  }
  .operations-infographic-cards li.color-1 i{
    color: var(--color-1);
  }
  
 .operations-infographic-cards li.color-2 i{
    color: var(--color-2);
  }
  
 .operations-infographic-cards li.color-3 i{
    color: var(--color-3);
  }
  
 .operations-infographic-cards li.color-4 i{
    color: var(--color-4);
  }
 .operations-infographic-cards li.color-1 .number-box{
    background: var(--color-1);
  }
  
 .operations-infographic-cards li.color-2 .number-box{
    background: var(--color-2);
  }
  
 .operations-infographic-cards li.color-3 .number-box{
    background: var(--color-3);
  }
  
 .operations-infographic-cards li.color-4 .number-box{
    background: var(--color-4);
  }
  
@media screen and (max-width:991px){
    .operations-infographic-cards{
        flex-wrap: wrap;
    }
    .operations-infographic-cards li {
        flex: 0 0 auto;
        width: calc(100% /2.5);
    }
}
@media screen and (max-width:578px){
    
    .operations-infographic-cards li {
        width: calc(100% /1);
    }
}