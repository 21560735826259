.nkab-explor-btn{
    border: 1px solid #0a146e;
    color: #0a146e;
}
.nkab-explor-btn:hover{
    border: 1px solid #2d3ab1;
    color: #2d3ab1;
}
.nkab-explor-btn2{
    background-color: #0a146e;
    color: #90d9ff;
}
.nkab-explor-btn2:hover{
    background-color: #2d3ab1;
    color: #c7b301;
}
.nkab-explor-btn, .nkab-explor-btn2{
    margin-right: 5px;
    margin-left: 5px;
    border-radius: 15px;
    padding: 7px;
    min-width: 150px;
    font-family: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
    font-size: 15px;
}

.nkab-explor-btnt{
    border: 1px solid #90d9ff;
    color: #90d9ff;
}
.nkab-explor-btnt:hover{
    border: 1px solid #1493d8;
    color: #1493d8;
}

.nkab-explor-btn2t{
    background-color: #0a146e;
    color: #90d9ff;
    border: 1px solid #90d9ff;
}
.nkab-explor-btn2t:hover{
    background-color: #2d3ab1;
    color: #c7b301;
}

.nkab-explor-btnt, .nkab-explor-btn2t{
    margin-right: 5px;
    margin-left: 5px;
    border-radius: 15px;
    padding: 7px;
    min-width: 150px;
    font-family: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
    font-size: 15px;
}
.nkab-status-text1{
    color: rgb(41, 40, 40);
    font-size: 30px;
    font-weight: 700;
}
.nkab-status-text2{
    font-size: 20px;
}
.nkab-explor-main-title{
    font-size: 14px;
    font-weight: 700;
}
.nkab-explor-price{
    font-size: 16px;
}
.nkab-explor-purcent-text{
    font-size: 25px;
}