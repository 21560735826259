
.box{
    padding:60px 0px;
}

.box-part{
    background:#FFF;
    border-radius:0;
    padding:50px 10px;
    margin:30px 0px;
}
.text{
    margin:20px 0px;
}

.fa{
     color:#4183D7;
}
.loadingFounded{
    color: #0d0492 !important;
    font-size: 20px !important;
}
.verif-button-panel{
    background-color: #0f161a;
    padding-top: 30px;
}