.welcom-gradient-button {
    background: linear-gradient(45deg, #c7b301, #233f8e);
    border: none;
    color: white;
    /* padding: 10px 20px; */
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 14px;
    margin: 10px 2px;
    cursor: pointer;
    border-radius: 20px;
    height: 40px;
    transition: background 0.3s ease;
}

.welcom-gradient-button:hover {
    background: linear-gradient(45deg, #233f8e, #c7b301);
}

.accueil-main_container {
    height: 57vh;
    color: #fff;
    background: url('https://nkab-exchange.net/images/acceuil1.jpg') no-repeat ;
    background-size: cover; /* Adjust background size */
    display: flex;
    align-items: center;
    justify-content: center;
  }


#banner{
    /* background-image: linear-gradient(to right, #bae7ff, #233f8e) !important; */
    color: #fff;
    padding-top: 5%;
}
.wellcome-title h1{
    font-size: 45px;
    font-weight: 600;
    color: #fff;
    padding: 0 10px 0 15px;
    /* text-shadow: 1px 1px 2px black, 0 0 25px #bae7ff, 0 0 5px #233f8e; */
}
.wellcome-title h6{
    font-size: 17px;
    font-weight: 400;
    color: #fff;
    /* text-shadow: 1px 1px 2px black, 0 0 25px #bae7ff, 0 0 5px #233f8e; */
}
.wellcome-title-t h1{
    color: #90d9ff !important;
}
.wellcome-title-t h6{
    color: #90d9ff !important;
}
.home-currency-img{
    animation: laby 7s infinite alternate;
}
.home-currency-img:hover{
    cursor: pointer;
    animation-play-state: paused;
}
@keyframes laby {
    20% {
        translate: 50px;
    }
}
.home-btn:hover{
    color: #fff;
}
.wellcome-subtitle{
    font-size: 17px;
    padding: 15px;
}
.play-btn{
    width: 45px;
    margin: 20px;
}
#banner a{
    color: #fff;
    text-decoration: none;
}
.bottom-img{
    width: 100%;
}
#services{
    padding: 80px 0;
}
.service-img{
    width: 100%;
    margin-top: 20px;
}
.services{
    padding: 20px;
}
.services h4{
    padding: 5px;
    margin-top: 25px;
    text-transform: uppercase;
}
.services-button-first{
    display: flex;
}
#services .btn-primary{
    box-shadow: none;
    padding: 8px 25px;
    border: none;
    border-radius: 20px;
    background-image: linear-gradient(to right, #bae7ff, #233f8e) !important;
}
.title{
    font-weight: 600;
    margin-bottom: 40px;
}
.promo-content{
    padding: 25px;
}
#about-us .about-us{
    background-color: #88d2fa;
    padding-bottom: 50px;
    padding-top: 50px;
}
.about-title{
    font-size: 30px;
    font-weight: 600;
    margin-top: 8%;
}
#about-us ul li{
    margin: 10px 0;
}
#testimonials{
    margin: 100px 0;
}
.testimonials{
    border-left: 4px solid #233f8e;
    margin-top: 50px;
    margin-bottom: 50px;
}
.testimonials img{
    border-radius: 50%;
    margin: 0 10px;
}
.user-details{
    display: inline-block;
}
#social-media .social-media{
    background-color: #88d2fa;
    padding: 100px 0;
}
#social-media p{
    font-size: 17px;
    font-weight: 600;
    margin-bottom: 30px;
}
.nkab-button-icons img{
    width: 300px;
    transition: 0.5s;
}
.services img{
    transition: 0.5s;
}
.operation-title{
    font-weight: 700;
    padding: 10px;
    text-transform: uppercase;
}
.social-icons img{
    width: 60px;
    transition: 0.5s;
}
.nkab-button-icons a:hover img{
    transform: translateY(-15px);
}
.services a:hover img{
    transform: translateY(-15px);
}
.social-icons a:hover img{
    transform: translateY(-10px);
}
#footer{
    background-image: linear-gradient(to right, #bae7ff, #233f8e) !important;
    color: #fff;
}
.footer-img{
    width: 100%;
}
.footer-content .footer-box{
    padding: 20px;
}
.footer-box img{
    width: 120px;
    margin-bottom: 20px;
}


.noselect {
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    -webkit-tap-highlight-color: transparent;
}

.button-noselect {
    width: 250px;
    height: 70px;
    cursor: pointer;
    background: #a75300;
    border: none;
    border-radius: 20px;
    box-shadow: 0px 10px 25px #a75300, 0px -10px 25px #bae7ff, inset 0px -5px 10px #a75300, inset 0px 5px 10px #bae7ff;
    font-family: Arial, sans-serif !important;
    color: white;
    font-size: 20px;
    transition: 500ms;
}

.button-noselect:hover {
    border: 2px solid #a75300;
    animation: hueRotation 2s linear infinite;
}

@keyframes hueRotation {
    to {filter: hue-rotate(360deg);}
}

.button-noselect:focus {
    outline: none;
}
.click-hier{
    color: #233f8e;
}
.titleh1{
    font-size: 70px;
}

.new-view{
    background-color: #94949480;
    border-radius: 5px;
    box-shadow: 0 3px 5px 0 #00040a65;
  }
.new-view:hover{
    box-shadow: 0 4px 5px 0 #00040ad3;
}
.home-crypto-title{
    color: #233f8e;
    font-size: 12px;
}

* {
  box-sizing: border-box;
}

/* #allservices{
    padding-bottom: 5em;
} */
._our-wrapper {
  margin: 0 auto;
  max-width: 1000px;
  border-radius: 5px;
}
.home-action-btn{
    background: #233f8e !important;
    font-weight: bolder;
    box-shadow: 0 5px 10px 0 #00040a;
}
.home-crypto-section{
    background-color: #dbdbdb33;
    border-radius: 20px;
    /* box-shadow: 0 1px 50px 0 #00040a49; */
    display: flex;
    flex-wrap: wrap;
}
.home-crypto-section-t{
    background-color: #0E233A !important;
}
.home-crypto-section2{
    background-color: #dbdbdb33;
    border-radius: 20px;
    /* box-shadow: 0 1px 50px 0 #00040a49; */
}
.home-crypto-section2-t{
    background-color: #0E233A;
    border-radius: 20px;
}
.nkab-lite-rate{
    background-color: #fdc27f33;
}
.nkab-lite-rate-t{
    background-color: #0e233a4d;
}
.nkab-lite-start{
    background-color: #233f8e;
    border-radius: 5px;
    color: #d9fcff;
    padding: 3px 5px 3px 5px;
    font-weight: 500;
    font-size: 13px;
}
.nkab-lite-start:hover{
    background-color: #fdc27f9c;
    color: #233f8e;
}
.nkab-items-name{
    background-color: #dbdbdbbb;
    border-radius: 20px;
}
.nkab-com{
    background-color: #dbdbdb33;
    border-radius: 20px;
}
.nkab-com-t{
    background-color: #0E233A;
    border-radius: 20px;
}
.community-support{
  background: transparent;
  border-radius: 20px;
  /* box-shadow: 0 1px 50px 0 #00040a21; */
}
/* .community-support:hover{
  background-color: #bce8ff;
} */
.community-title{
    font-size: 12.4px !important;
}
.community-support a{
    text-decoration: none;
}
.wellcome-img{
  width: 80%;
}
.nkab-annonceur{
    padding: 10px 3px 10px 3px;
    font-size: 15px;
    border-radius: 5px;
    color: white;
    background-color: #8a7c00;
}
.nkab-annonceur-num{
    color: #ffeb3c;
}
.home-buy-btn{
    background-color: #059624;
    color: white;
    padding: 4px 5px 4px 5px;
    border-radius: 5px;
}
.home-buy-btn:hover{
    background-color: #09fc1d3b;
    color: #059624 !important;

}
.home-sell-btn{
    background-color: #ad2323;
    color: white;
    padding: 4px 5px 4px 5px;
    border-radius: 5px;
}
.home-sell-btn:hover{
    background-color: #d602027a;
    color: white;
}
._our-header {
  color: #333;
  padding: 30px 30px 0;
  text-align: center;
}
.header__title {
  margin: 0;
  text-transform: uppercase;
  font-size: 1.7em;
  font-weight: 500;
  line-height: 1.1;
}
.header__subtitle {
  margin: 0;
  font-size: 1.5em;
  color: #333333b0;
  font-family: "Yesteryear", cursive;
  font-weight: 500;
  line-height: 1.1;
}
.header__subtitle-t {
  color: #0E233A !important;
}
.card_subtitle{
    color: #333;
    font-size: 11px;
    text-transform: uppercase;
}
._our-cards {
  padding: 15px;
  display: flex;
  flex-flow: row wrap;
}

._our-card {
  margin: 15px;
  width: calc((100% / 3) - 30px);
  transition: all 0.2s ease-in-out;
}
.nkab-community{
  margin: auto;
  padding: 0px 10px 3px 10px;
  border-radius: 50px;
  box-shadow: 0 1px 2px 0 #00040a25;
}
.nkab-deconnect{
  margin: auto;
  padding: 0px 10px 3px 10px;
  border-radius: 20px;
  /* box-shadow: 0 1px 2px 0 #00040a25; */
}
.community-buy-and-sell{
    background-color: #0b1f3583;
    border-radius: 50px;
    padding-bottom: 7px;
}
.telegram-bg{
    background-color: #2186b85d;
    border: 2px solid #2186b8;
    width: 15%;
}
.whatsapp-bg{
    background-color: #03c10027;
    border: 2px solid #03c100;
    width: 15%;
}
.deconnect-bg{
    background-color: #c1000027;
    border: 2px solid #c10000;
}
.connect-btn1{
    background-color: #c7b30127 !important;
    border-radius: 50px !important;
    margin-right: 5px !important;
}
.connect-btn1:hover{
    background-color: #c7b30159 !important;
}
.connect-btn2{
    background-color: #c7b301 !important;
    border-radius: 50px !important;
}
.connect-btn2:hover{
    background-color: #948501 !important;
}
/* .connect-bg{
    background-color: #00c14a27;
    border: 2px solid #00c140;
} */
.telegram-bg:hover{
    background-color: #57b5e45e;
}
.whatsapp-bg:hover{
    background-color: #21c51e49;
}
.telegram-color{
    color: #1e7cac !important;
}
.whatsapp-color{
    color: #04a001 !important;
}

@media screen and (max-width: 991px) {
  ._our-card {
    width: calc((100% / 2) - 30px);
  }
}
@media screen and (max-width: 767px) {
  ._our-card {
    width: 100%;
  }
}
._our-card:hover .card__inner {
    border-left: 10px solid #c7b301;
    transform: scale(1.05);
}
.card__inner {
  width: 100%;
  padding: 30px;
  border-radius: 20px !important;
  position: relative;
  cursor: pointer;
  background-color: #dbdbdb33;
  color: #eceef1;
  font-size: 1.5em;
  /* text-transform: uppercase; */
  text-align: center;
  transition: all 0.2s ease-in-out;
  height: 18rem;
}
.card__inner-t {
  background-color: #0E233A !important;
}
.card__inner:after {
  transition: all 0.3s ease-in-out;
}
.card__inner .fa {
  width: 100%;
  margin-top: 0.25em;
}
span.card__inner-img{
    margin: 5px;
    padding: 5px;
}
.card__inner-img{
    width: 30px;
}
.bg-color-and-border{
    background-color: #c7b301 !important;
    border: none !important;
}
._our-card.bg-color-and-border:hover{
    background-color: #233f8e !important;
}
.card__expander {
  transition: all 0.2s ease-in-out;
  background-color: #333a45;
  width: 100%;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  text-transform: uppercase;
  color: #eceef1;
  font-size: 1.5em;
}
.card__expander .fa {
  font-size: 0.75em;
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
}
.card__expander .fa:hover {
  opacity: 0.9;
}
._our-card.is-collapsed .card__inner:after {
  content: "";
  opacity: 0;
}
._our-card.is-collapsed .card__expander {
  max-height: 0;
  min-height: 0;
  overflow: hidden;
  margin-top: 0;
  opacity: 0;
}
._our-card.is-expanded .card__inner {
  background-color: #c7b301;
}
._our-card.is-expanded .card__inner:after {
  content: "";
  opacity: 1;
  display: block;
  height: 0;
  width: 0;
  position: absolute;
  bottom: -30px;
  left: calc(50% - 15px);
  border-left: 15px solid transparent;
  border-right: 15px solid transparent;
  border-bottom: 15px solid #333a45;
}
._our-card.is-expanded .card__inner .fa:before {
  content: "";
}
._our-card.is-expanded .card__expander {
  max-height: 1000px;
  min-height: 200px;
  overflow: visible;
  margin-top: 30px;
  opacity: 1;
}
._our-card.is-expanded:hover .card__inner {
  transform: scale(1);
}
._our-card.is-inactive .card__inner {
  pointer-events: none;
  opacity: 0.5;
}
._our-card.is-inactive:hover .card__inner {
  background-color: #949fb0;
  transform: scale(1);
}




@media screen and (min-width: 992px) {
  ._our-card:nth-of-type(3n+2) .card__expander {
    margin-left: calc(-100% - 30px);
  }

  ._our-card:nth-of-type(3n+3) .card__expander {
    margin-left: calc(-200% - 60px);
  }

  ._our-card:nth-of-type(3n+4) {
    clear: left;
  }

  .card__expander {
    width: calc(300% + 60px);
  }
}
@media screen and (min-width: 768px) and (max-width: 991px) {
  ._our-card:nth-of-type(2n+2) .card__expander {
    margin-left: calc(-100% - 30px);
  }

  ._our-card:nth-of-type(2n+3) {
    clear: left;
  }

  .card__expander {
    width: calc(200% + 30px);
  }
}



@media screen and (min-width: 199px) and (max-width: 1350px) {
    .wellcome-title{
        font-size: 27px;
    }
    .nkab-button-icons img{
        width: 250px;
    }
    
    .button-noselect {
        width: 250px;
        height: 50px;
        color: white;
        font-size: 14px !important;
    }
    .title, .card-title{
        font-size: 12px !important;
        font-weight: 600;
    }
}
@media screen and (min-width: 199px) and (max-width: 1300px) {
    .wellcome-title{
        font-size: 27px;
    }
    .nkab-button-icons img{
        width: 250px;
    }
    
    .button-noselect {
        width: 250px;
        height: 50px;
        color: white;
        font-size: 14px !important;
    }
    .title, .card-title{
        font-size: 12px !important;
        font-weight: 600;
    }
}
@media screen and (min-width: 199px) and (max-width: 1250px) {
    .wellcome-title{
        font-size: 27px;
    }
    .nkab-button-icons img{
        width: 250px;
    }
    
    .button-noselect {
        width: 250px;
        height: 50px;
        color: white;
        font-size: 14px !important;
    }
    .title, .card-title{
        font-size: 12px !important;
        font-weight: 600;
    }
}
@media screen and (min-width: 199px) and (max-width: 1200px) {
    .wellcome-title{
        font-size: 27px;
    }
    .nkab-button-icons img{
        width: 250px;
    }
    
    .button-noselect {
        width: 250px;
        height: 50px;
        color: white;
        font-size: 14px !important;
    }
    .title, .card-title{
        font-size: 12px !important;
        font-weight: 600;
    }
}
@media screen and (min-width: 199px) and (max-width: 1150px) {
    .wellcome-title{
        font-size: 27px;
    }
    .nkab-button-icons img{
        width: 200px;
    }
    
    .button-noselect {
        width: 170px;
        height: 50px;
        color: white;
        font-size: 12px !important;
    }
    .title, .card-title{
        font-size: 12px !important;
        font-weight: 600;
    }
}
@media screen and (min-width: 199px) and (max-width: 1100px) {
    .wellcome-title{
        font-size: 27px;
    }
    .nkab-button-icons img{
        width: 200px;
    }
    
    .button-noselect {
        width: 170px;
        height: 50px;
        color: white;
        font-size: 12px !important;
    }
    .title, .card-title{
        font-size: 12px !important;
        font-weight: 600;
    }
}
@media screen and (min-width: 199px) and (max-width: 1050px) {
    .wellcome-title{
        font-size: 27px;
    }
    .nkab-button-icons img{
        width: 200px;
    }
    
    .button-noselect {
        width: 170px;
        height: 50px;
        color: white;
        font-size: 12px !important;
    }
    .title, .card-title{
        font-size: 12px !important;
        font-weight: 600;
    }
}
@media screen and (min-width: 199px) and (max-width: 1000px) {
    .wellcome-title{
        font-size: 27px;
    }
    .nkab-button-icons img{
        width: 200px;
    }
    
    .button-noselect {
        width: 150px;
        height: 50px;
        color: white;
        font-size: 12px !important;
    }
    .title, .card-title{
        font-size: 12px !important;
        font-weight: 600;
    }
}
@media screen and (min-width: 199px) and (max-width: 950px) {
    .wellcome-title{
        font-size: 27px;
    }
    .nkab-button-icons img{
        width: 150px;
    }
    
    .button-noselect {
        width: 150px;
        height: 50px;
        color: white;
        font-size: 11px !important;
    }
    .title, .card-title{
        font-size: 12px !important;
        font-weight: 600;
    }
}
@media screen and (min-width: 199px) and (max-width: 900px) {
    .wellcome-title{
        font-size: 27px;
    }
    .nkab-button-icons img{
        width: 150px;
    }
    
    .button-noselect {
        width: 150px;
        height: 50px;
        color: white;
        font-size: 11px !important;
    }
    .title, .card-title{
        font-size: 12px !important;
        font-weight: 600;
    }
}
@media screen and (min-width: 199px) and (max-width: 850px) {
    .wellcome-title{
        font-size: 27px;
    }
    .nkab-button-icons img{
        width: 150px;
    }
    
    .button-noselect {
        width: 140px;
        height: 50px;
        color: white;
        font-size: 11px !important;
    }
    .title, .card-title{
        font-size: 12px !important;
        font-weight: 600;
    }
}
@media screen and (min-width: 199px) and (max-width: 800px) {
    .wellcome-title{
        font-size: 27px;
    }
    .nkab-button-icons img{
        width: 150px;
    }
    
    .button-noselect {
        width: 150px;
        height: 50px;
        color: white;
        font-size: 11px !important;
    }
    .title, .card-title{
        font-size: 12px !important;
        font-weight: 600;
    }
}
@media screen and (min-width: 199px) and (max-width: 750px) {
    .wellcome-title{
        font-size: 27px;
    }
    .nkab-button-icons img{
        width: 230px;
    }
    
    .button-noselect {
        width: 238px;
        height: 50px;
        color: white;
        font-size: 13px !important;
    }
    .title, .card-title{
        font-size: 12px !important;
        font-weight: 600;
    }
}
@media screen and (min-width: 199px) and (max-width: 700px) {
    .wellcome-title{
        font-size: 27px;
    }
    .nkab-button-icons img{
        width: 230px;
    }
    
    .button-noselect {
        width: 238px;
        height: 50px;
        color: white;
        font-size: 13px !important;
    }
    .title, .card-title{
        font-size: 12px !important;
        font-weight: 600;
    }
}
@media screen and (min-width: 199px) and (max-width: 650px) {
    .wellcome-title{
        font-size: 27px;
    }
    .nkab-button-icons img{
        width: 230px;
    }
    
    .button-noselect {
        width: 238px;
        height: 50px;
        color: white;
        font-size: 13px !important;
    }
    .title, .card-title{
        font-size: 12px !important;
        font-weight: 600;
    }
}
@media screen and (min-width: 199px) and (max-width: 600px) {
    .wellcome-title{
        font-size: 27px;
    }
    .nkab-button-icons img{
        width: 230px;
    }
    
    .button-noselect {
        width: 238px;
        height: 50px;
        color: white;
        font-size: 13px !important;
    }
    .title, .card-title{
        font-size: 12px !important;
        font-weight: 600;
    }
}
@media screen and (min-width: 199px) and (max-width: 550px) {
    .wellcome-title{
        font-size: 27px;
    }
    .nkab-button-icons img{
        width: 230px;
    }
    
    .button-noselect {
        width: 238px;
        height: 50px;
        color: white;
        font-size: 13px !important;
    }
    .title, .card-title{
        font-size: 12px !important;
        font-weight: 600;
    }
}
@media screen and (min-width: 199px) and (max-width: 500px) {
    .wellcome-title{
        font-size: 27px;
    }
    .nkab-button-icons img{
        width: 190px;
    }
    
    .button-noselect {
        width: 215px;
        height: 50px;
        color: white;
        font-size: 13px !important;
    }
    .title, .card-title{
        font-size: 12px !important;
        font-weight: 600;
    }
}
@media screen and (min-width: 199px) and (max-width: 450px) {
    .wellcome-title{
        font-size: 27px;
    }
    .nkab-button-icons img{
        width: 150px;
    }
    
    .button-noselect {
        width: 161px;
        height: 50px;
        color: white;
        font-size: 13px !important;
    }
    .title, .card-title{
        font-size: 12px !important;
        font-weight: 600;
    }
}
@media screen and (min-width: 199px) and (max-width: 428px) {
    .wellcome-title{
        font-size: 27px;
    }
    .nkab-button-icons img{
        width: 150px;
    }
    
    .button-noselect {
        width: 170px;
        height: 50px;
        color: white;
        font-size: 13px !important;
    }
    .title, .card-title{
        font-size: 12px !important;
        font-weight: 600;
    }
}
@media screen and (min-width: 199px) and (max-width: 400px) {
    .wellcome-title{
        font-size: 27px;
    }
    .nkab-button-icons img{
        width: 150px;
    }
    
    .button-noselect {
        width: 165px;
        height: 50px;
        color: white;
        font-size: 12px !important;
    }
    .title, .card-title{
        font-size: 12px !important;
        font-weight: 600;
    }
}
@media screen and (min-width: 199px) and (max-width: 412px) {
    .wellcome-title{
        font-size: 27px;
    }
    .nkab-button-icons img{
        width: 150px;
    }
    
    .button-noselect {
        width: 163px;
        height: 50px;
        color: white;
        font-size: 12px !important;
    }
    .title, .card-title{
        font-size: 12px !important;
        font-weight: 600;
    }
}
@media screen and (min-width: 199px) and (max-width: 391px) {
    .wellcome-title{
        font-size: 27px;
    }
    .nkab-button-icons img{
        width: 150px;
    }
    
    .button-noselect {
        width: 159px;
        height: 65px;
        color: white;
        font-size: 12px !important;
    }
    .title, .card-title{
        font-size: 12px !important;
        font-weight: 600;
    }
}
@media screen and (min-width: 199px) and (max-width: 390px) {
    .wellcome-title{
        font-size: 27px;
    }
    .nkab-button-icons img{
        width: 150px;
    }
    
    .button-noselect {
        width: 151px;
        height: 65px;
        color: white;
        font-size: 12px !important;
    }
    .title, .card-title{
        font-size: 12px !important;
        font-weight: 600;
    }
}
@media screen and (min-width: 199px) and (max-width: 389px) {
    .wellcome-title{
        font-size: 27px;
    }
    .nkab-button-icons img{
        width: 150px;
    }
    
    .button-noselect {
        width: 158px;
        height: 65px;
        color: white;
        font-size: 12px !important;
    }
    .title, .card-title{
        font-size: 12px !important;
        font-weight: 600;
    }
}
@media screen and (min-width: 199px) and (max-width: 388px) {
    .wellcome-title{
        font-size: 27px;
    }
    .nkab-button-icons img{
        width: 150px;
    }
    
    .button-noselect {
        width: 158px;
        height: 65px;
        color: white;
        font-size: 12px !important;
    }
    .title, .card-title{
        font-size: 12px !important;
        font-weight: 600;
    }
}
@media screen and (min-width: 199px) and (max-width: 387px) {
    .wellcome-title{
        font-size: 27px;
    }
    .nkab-button-icons img{
        width: 150px;
    }
    
    .button-noselect {
        width: 156px;
        height: 65px;
        color: white;
        font-size: 12px !important;
    }
    .title, .card-title{
        font-size: 12px !important;
        font-weight: 600;
    }
}
@media screen and (min-width: 199px) and (max-width: 386px) {
    .wellcome-title{
        font-size: 27px;
    }
    .nkab-button-icons img{
        width: 150px;
    }
    
    .button-noselect {
        width: 156px;
        height: 65px;
        color: white;
        font-size: 12px !important;
    }
    .title, .card-title{
        font-size: 12px !important;
        font-weight: 600;
    }
}
@media screen and (min-width: 199px) and (max-width: 385px) {
    .wellcome-title{
        font-size: 27px;
    }
    .nkab-button-icons img{
        width: 150px;
    }
    
    .button-noselect {
        width: 155px;
        height: 65px;
        color: white;
        font-size: 12px !important;
    }
    .title, .card-title{
        font-size: 12px !important;
        font-weight: 600;
    }
}
@media screen and (min-width: 199px) and (max-width: 384px) {
    .wellcome-title{
        font-size: 27px;
    }
    .nkab-button-icons img{
        width: 150px;
    }
    
    .button-noselect {
        width: 147px;
        height: 65px;
        color: white;
        font-size: 12px !important;
    }
    .title, .card-title{
        font-size: 12px !important;
        font-weight: 600;
    }
}
@media screen and (min-width: 199px) and (max-width: 383px) {
    .wellcome-title{
        font-size: 27px;
    }
    .nkab-button-icons img{
        width: 150px;
    }
    
    .button-noselect {
        width: 155px;
        height: 65px;
        color: white;
        font-size: 12px !important;
    }
    .title, .card-title{
        font-size: 12px !important;
        font-weight: 600;
    }
}
@media screen and (min-width: 199px) and (max-width: 382px) {
    .wellcome-title{
        font-size: 27px;
    }
    .nkab-button-icons img{
        width: 150px;
    }
    
    .button-noselect {
        width: 155px;
        height: 65px;
        color: white;
        font-size: 12px !important;
    }
    .title, .card-title{
        font-size: 12px !important;
        font-weight: 600;
    }
}
@media screen and (min-width: 199px) and (max-width: 381px) {
    .wellcome-title{
        font-size: 27px;
    }
    .nkab-button-icons img{
        width: 150px;
    }
    
    .button-noselect {
        width: 155px;
        height: 65px;
        color: white;
        font-size: 12px !important;
    }
    .title, .card-title{
        font-size: 12px !important;
        font-weight: 600;
    }
}
@media screen and (min-width: 199px) and (max-width: 380px) {
    .wellcome-title{
        font-size: 27px;
    }
    .nkab-button-icons img{
        width: 150px;
    }
    
    .button-noselect {
        width: 155px;
        height: 65px;
        color: white;
        font-size: 12px !important;
    }
    .title, .card-title{
        font-size: 12px !important;
        font-weight: 600;
    }
}
@media screen and (min-width: 199px) and (max-width: 379px) {
    .wellcome-title{
        font-size: 27px;
    }
    .nkab-button-icons img{
        width: 150px;
    }
    
    .button-noselect {
        width: 140px;
        height: 65px;
        color: white;
        font-size: 12px !important;
    }
    .title, .card-title{
        font-size: 12px !important;
        font-weight: 600;
    }
}
@media screen and (min-width: 199px) and (max-width: 378px) {
    .wellcome-title{
        font-size: 27px;
    }
    .nkab-button-icons img{
        width: 150px;
    }
    
    .button-noselect {
        width: 140px;
        height: 65px;
        color: white;
        font-size: 12px !important;
    }
    .title, .card-title{
        font-size: 12px !important;
        font-weight: 600;
    }
}
@media screen and (min-width: 199px) and (max-width: 377px) {
    .wellcome-title{
        font-size: 27px;
    }
    .nkab-button-icons img{
        width: 150px;
    }
    
    .button-noselect {
        width: 140px;
        height: 65px;
        color: white;
        font-size: 12px !important;
    }
    .title, .card-title{
        font-size: 12px !important;
        font-weight: 600;
    }
}
@media screen and (min-width: 199px) and (max-width: 376px) {
    .wellcome-title{
        font-size: 27px;
    }
    .nkab-button-icons img{
        width: 150px;
    }
    
    .button-noselect {
        width: 140px;
        height: 65px;
        color: white;
        font-size: 12px !important;
    }
    .title, .card-title{
        font-size: 12px !important;
        font-weight: 600;
    }
}
@media screen and (min-width: 199px) and (max-width: 375px) {
    .wellcome-title{
        font-size: 27px;
    }
    .nkab-button-icons img{
        width: 150px;
    }
    
    .button-noselect {
        width: 143px;
        height: 65px;
        color: white;
        font-size: 12px !important;
    }
    .title, .card-title{
        font-size: 12px !important;
        font-weight: 600;
    }
}
@media screen and (min-width: 199px) and (max-width: 374px) {
    .wellcome-title{
        font-size: 27px;
    }
    .nkab-button-icons img{
        width: 150px;
    }
    
    .button-noselect {
        width: 140px;
        height: 65px;
        color: white;
        font-size: 12px !important;
    }
    .title, .card-title{
        font-size: 12px !important;
        font-weight: 600;
    }
}
@media screen and (min-width: 199px) and (max-width: 373px) {
    .wellcome-title{
        font-size: 27px;
    }
    .nkab-button-icons img{
        width: 150px;
    }
    
    .button-noselect {
        width: 140px;
        height: 65px;
        color: white;
        font-size: 12px !important;
    }
    .title, .card-title{
        font-size: 12px !important;
        font-weight: 600;
    }
}
@media screen and (min-width: 199px) and (max-width: 372px) {
    .wellcome-title{
        font-size: 27px;
    }
    .nkab-button-icons img{
        width: 150px;
    }
    
    .button-noselect {
        width: 140px;
        height: 65px;
        color: white;
        font-size: 12px !important;
    }
    .title, .card-title{
        font-size: 12px !important;
        font-weight: 600;
    }
}
@media screen and (min-width: 199px) and (max-width: 371px) {
    .wellcome-title{
        font-size: 27px;
    }
    .nkab-button-icons img{
        width: 150px;
    }
    
    .button-noselect {
        width: 140px;
        height: 65px;
        color: white;
        font-size: 12px !important;
    }
    .title, .card-title{
        font-size: 12px !important;
        font-weight: 600;
    }
}
@media screen and (min-width: 199px) and (max-width: 370px) {
    .wellcome-title{
        font-size: 27px;
    }
    .nkab-button-icons img{
        width: 150px;
    }
    
    .button-noselect {
        width: 140px;
        height: 65px;
        color: white;
        font-size: 12px !important;
    }
    .title, .card-title{
        font-size: 12px !important;
        font-weight: 600;
    }
}
@media screen and (min-width: 199px) and (max-width: 369px) {
    .wellcome-title{
        font-size: 27px;
    }
    .nkab-button-icons img{
        width: 150px;
    }
    
    .button-noselect {
        width: 140px;
        height: 65px;
        color: white;
        font-size: 12px !important;
    }
    .title, .card-title{
        font-size: 12px !important;
        font-weight: 600;
    }
}
@media screen and (min-width: 199px) and (max-width: 368px) {
    .wellcome-title{
        font-size: 27px;
    }
    .nkab-button-icons img{
        width: 150px;
    }
    
    .button-noselect {
        width: 140px;
        height: 65px;
        color: white;
        font-size: 12px !important;
    }
    .title, .card-title{
        font-size: 12px !important;
        font-weight: 600;
    }
}
@media screen and (min-width: 199px) and (max-width: 367px) {
    .wellcome-title{
        font-size: 27px;
    }
    .nkab-button-icons img{
        width: 150px;
    }
    
    .button-noselect {
        width: 140px;
        height: 65px;
        color: white;
        font-size: 12px !important;
    }
    .title, .card-title{
        font-size: 12px !important;
        font-weight: 600;
    }
}
@media screen and (min-width: 199px) and (max-width: 366px) {
    .wellcome-title{
        font-size: 27px;
    }
    .nkab-button-icons img{
        width: 150px;
    }
    
    .button-noselect {
        width: 140px;
        height: 65px;
        color: white;
        font-size: 12px !important;
    }
    .title, .card-title{
        font-size: 12px !important;
        font-weight: 600;
    }
}
@media screen and (min-width: 199px) and (max-width: 365px) {
    .wellcome-title{
        font-size: 27px;
    }
    .nkab-button-icons img{
        width: 150px;
    }
    
    .button-noselect {
        width: 140px;
        height: 65px;
        color: white;
        font-size: 12px !important;
    }
    .title, .card-title{
        font-size: 12px !important;
        font-weight: 600;
    }
}
@media screen and (min-width: 199px) and (max-width: 364px) {
    .wellcome-title{
        font-size: 27px;
    }
    .nkab-button-icons img{
        width: 150px;
    }
    
    .button-noselect {
        width: 140px;
        height: 65px;
        color: white;
        font-size: 12px !important;
    }
    .title, .card-title{
        font-size: 12px !important;
        font-weight: 600;
    }
}
@media screen and (min-width: 199px) and (max-width: 363px) {
    .wellcome-title{
        font-size: 27px;
    }
    .nkab-button-icons img{
        width: 150px;
    }
    
    .button-noselect {
        width: 140px;
        height: 65px;
        color: white;
        font-size: 12px !important;
    }
    .title, .card-title{
        font-size: 12px !important;
        font-weight: 600;
    }
}
@media screen and (min-width: 199px) and (max-width: 362px) {
    .wellcome-title{
        font-size: 27px;
    }
    .nkab-button-icons img{
        width: 150px;
    }
    
    .button-noselect {
        width: 140px;
        height: 65px;
        color: white;
        font-size: 12px !important;
    }
    .title, .card-title{
        font-size: 12px !important;
        font-weight: 600;
    }
}
@media screen and (min-width: 199px) and (max-width: 361px) {
    .wellcome-title{
        font-size: 27px;
    }
    .nkab-button-icons img{
        width: 150px;
    }
    
    .button-noselect {
        width: 135px;
        height: 65px;
        color: white;
        font-size: 12px !important;
    }
    .title, .card-title{
        font-size: 12px !important;
        font-weight: 600;
    }
}
@media screen and (min-width: 199px) and (max-width: 350px) {
    .wellcome-title{
        font-size: 27px;
    }
    .nkab-button-icons img{
        width: 150px;
    }
    
    .button-noselect {
        width: 140px;
        height: 65px;
        color: white;
        font-size: 12px !important;
    }
    .title, .card-title{
        font-size: 12px !important;
        font-weight: 600;
    }
}
@media screen and (min-width: 199px) and (max-width: 340px) {
    .wellcome-title{
        font-size: 27px;
    }
    .nkab-button-icons img{
        width: 150px;
    }
    
    .button-noselect {
        width: 130px;
        height: 70px;
        color: white;
        font-size: 12px !important;
    }
    .title, .card-title{
        font-size: 12px !important;
        font-weight: 600;
    }
}
@media screen and (min-width: 199px) and (max-width: 338px) {
    .wellcome-title{
        font-size: 27px;
    }
    .nkab-button-icons img{
        width: 150px;
    }
    
    .button-noselect {
        width: 130px;
        height: 70px;
        color: white;
        font-size: 12px !important;
    }
    .title, .card-title{
        font-size: 12px !important;
        font-weight: 600;
    }
}
@media screen and (min-width: 199px) and (max-width: 337px) {
    .wellcome-title{
        font-size: 27px;
    }
    .nkab-button-icons img{
        width: 150px;
    }
    
    .button-noselect {
        width: 130px;
        height: 70px;
        color: white;
        font-size: 12px !important;
    }
    .title, .card-title{
        font-size: 12px !important;
        font-weight: 600;
    }
}
@media screen and (min-width: 199px) and (max-width: 336px) {
    .wellcome-title{
        font-size: 27px;
    }
    .nkab-button-icons img{
        width: 150px;
    }
    
    .button-noselect {
        width: 130px;
        height: 70px;
        color: white;
        font-size: 12px !important;
    }
    .title, .card-title{
        font-size: 12px !important;
        font-weight: 600;
    }
}
@media screen and (min-width: 199px) and (max-width: 335px) {
    .wellcome-title{
        font-size: 27px;
    }
    .nkab-button-icons img{
        width: 150px;
    }
    
    .button-noselect {
        width: 130px;
        height: 70px;
        color: white;
        font-size: 12px !important;
    }
    .title, .card-title{
        font-size: 12px !important;
        font-weight: 600;
    }
}
@media screen and (min-width: 199px) and (max-width: 334px) {
    .wellcome-title{
        font-size: 27px;
    }
    .nkab-button-icons img{
        width: 150px;
    }
    
    .button-noselect {
        width: 130px;
        height: 70px;
        color: white;
        font-size: 12px !important;
    }
    .title, .card-title{
        font-size: 12px !important;
        font-weight: 600;
    }
}
@media screen and (min-width: 199px) and (max-width: 333px) {
    .wellcome-title{
        font-size: 27px;
    }
    .nkab-button-icons img{
        width: 150px;
    }
    
    .button-noselect {
        width: 130px;
        height: 70px;
        color: white;
        font-size: 12px !important;
    }
    .title, .card-title{
        font-size: 12px !important;
        font-weight: 600;
    }
}
@media screen and (min-width: 199px) and (max-width: 332px) {
    .wellcome-title{
        font-size: 27px;
    }
    .nkab-button-icons img{
        width: 150px;
    }
    
    .button-noselect {
        width: 130px;
        height: 70px;
        color: white;
        font-size: 12px !important;
    }
    .title, .card-title{
        font-size: 12px !important;
        font-weight: 600;
    }
}
@media screen and (min-width: 199px) and (max-width: 331px) {
    .wellcome-title{
        font-size: 27px;
    }
    .nkab-button-icons img{
        width: 150px;
    }
    
    .button-noselect {
        width: 130px;
        height: 70px;
        color: white;
        font-size: 12px !important;
    }
    .title, .card-title{
        font-size: 12px !important;
        font-weight: 600;
    }
}
@media screen and (min-width: 199px) and (max-width: 330px) {
    .wellcome-title{
        font-size: 27px;
    }
    .nkab-button-icons img{
        width: 150px;
    }
    
    .button-noselect {
        width: 130px;
        height: 70px;
        color: white;
        font-size: 12px !important;
    }
    .title, .card-title{
        font-size: 12px !important;
        font-weight: 600;
    }
}
@media screen and (min-width: 199px) and (max-width: 329px) {
    .wellcome-title{
        font-size: 27px;
    }
    .nkab-button-icons img{
        width: 120px;
    }
    
    .button-noselect {
        width: 129px;
        height: 70px;
        color: white;
        font-size: 12px !important;
    }
    .title, .card-title{
        font-size: 12px !important;
        font-weight: 600;
    }
}
@media screen and (min-width: 199px) and (max-width: 328px) {
    .wellcome-title{
        font-size: 27px;
    }
    .nkab-button-icons img{
        width: 120px;
    }
    
    .button-noselect {
        width: 129px;
        height: 70px;
        color: white;
        font-size: 12px !important;
    }
    .title, .card-title{
        font-size: 12px !important;
        font-weight: 600;
    }
}
@media screen and (min-width: 199px) and (max-width: 327px) {
    .wellcome-title{
        font-size: 27px;
    }
    .nkab-button-icons img{
        width: 120px;
    }
    
    .button-noselect {
        width: 129px;
        height: 70px;
        color: white;
        font-size: 12px !important;
    }
    .title, .card-title{
        font-size: 12px !important;
        font-weight: 600;
    }
}
@media screen and (min-width: 199px) and (max-width: 326px) {
    .wellcome-title{
        font-size: 27px;
    }
    .nkab-button-icons img{
        width: 120px;
    }
    
    .button-noselect {
        width: 129px;
        height: 70px;
        color: white;
        font-size: 12px !important;
    }
    .title, .card-title{
        font-size: 12px !important;
        font-weight: 600;
    }
}
@media screen and (min-width: 199px) and (max-width: 325px) {
    .wellcome-title{
        font-size: 27px;
    }
    .nkab-button-icons img{
        width: 120px;
    }
    
    .button-noselect {
        width: 127px;
        height: 70px;
        color: white;
        font-size: 12px !important;
    }
    .title, .card-title{
        font-size: 12px !important;
        font-weight: 600;
    }
}
@media screen and (min-width: 199px) and (max-width: 324px) {
    .wellcome-title{
        font-size: 27px;
    }
    .nkab-button-icons img{
        width: 120px;
    }
    
    .button-noselect {
        width: 127px;
        height: 70px;
        color: white;
        font-size: 12px !important;
    }
    .title, .card-title{
        font-size: 12px !important;
        font-weight: 600;
    }
}
@media screen and (min-width: 199px) and (max-width: 323px) {
    .wellcome-title{
        font-size: 27px;
    }
    .nkab-button-icons img{
        width: 120px;
    }
    
    .button-noselect {
        width: 127px;
        height: 70px;
        color: white;
        font-size: 12px !important;
    }
    .title, .card-title{
        font-size: 12px !important;
        font-weight: 600;
    }
}
@media screen and (min-width: 199px) and (max-width: 322px) {
    .wellcome-title{
        font-size: 27px;
    }
    .nkab-button-icons img{
        width: 120px;
    }
    
    .button-noselect {
        width: 127px;
        height: 70px;
        color: white;
        font-size: 12px !important;
    }
    .title, .card-title{
        font-size: 12px !important;
        font-weight: 600;
    }
}
@media screen and (min-width: 199px) and (max-width: 321px) {
    .wellcome-title{
        font-size: 27px;
    }
    .nkab-button-icons img{
        width: 120px;
    }
    
    .button-noselect {
        width: 127px;
        height: 70px;
        color: white;
        font-size: 12px !important;
    }
    .title, .card-title{
        font-size: 12px !important;
        font-weight: 600;
    }
}
@media screen and (min-width: 199px) and (max-width: 320px) {
    .wellcome-title{
        font-size: 27px;
    }
    .nkab-button-icons img{
        width: 120px;
    }
    
    .button-noselect {
        width: 124px;
        height: 70px;
        color: white;
        font-size: 12px !important;
    }
    .title, .card-title{
        font-size: 12px !important;
        font-weight: 600;
    }
}
@media screen and (min-width: 199px) and (max-width: 319px) {
    .wellcome-title{
        font-size: 27px;
    }
    .nkab-button-icons img{
        width: 120px;
    }
    
    .button-noselect {
        width: 124px;
        height: 70px;
        color: white;
        font-size: 12px !important;
    }
    .title, .card-title{
        font-size: 12px !important;
        font-weight: 600;
    }
}
@media screen and (min-width: 199px) and (max-width: 318px) {
    .wellcome-title{
        font-size: 27px;
    }
    .nkab-button-icons img{
        width: 120px;
    }
    
    .button-noselect {
        width: 124px;
        height: 70px;
        color: white;
        font-size: 12px !important;
    }
    .title, .card-title{
        font-size: 12px !important;
        font-weight: 600;
    }
}
@media screen and (min-width: 199px) and (max-width: 317px) {
    .wellcome-title{
        font-size: 27px;
    }
    .nkab-button-icons img{
        width: 120px;
    }
    
    .button-noselect {
        width: 122px;
        height: 70px;
        color: white;
        font-size: 12px !important;
    }
    .title, .card-title{
        font-size: 12px !important;
        font-weight: 600;
    }
}
@media screen and (min-width: 199px) and (max-width: 316px) {
    .wellcome-title{
        font-size: 27px;
    }
    .nkab-button-icons img{
        width: 120px;
    }
    
    .button-noselect {
        width: 122px;
        height: 70px;
        color: white;
        font-size: 12px !important;
    }
    .title, .card-title{
        font-size: 12px !important;
        font-weight: 600;
    }
}
@media screen and (min-width: 199px) and (max-width: 315px) {
    .wellcome-title{
        font-size: 27px;
    }
    .nkab-button-icons img{
        width: 120px;
    }
    
    .button-noselect {
        width: 121px;
        height: 70px;
        color: white;
        font-size: 12px !important;
    }
    .title, .card-title{
        font-size: 12px !important;
        font-weight: 600;
    }
}
@media screen and (min-width: 199px) and (max-width: 314px) {
    .wellcome-title{
        font-size: 27px;
    }
    .nkab-button-icons img{
        width: 120px;
    }
    
    .button-noselect {
        width: 121px;
        height: 70px;
        color: white;
        font-size: 12px !important;
    }
    .title, .card-title{
        font-size: 12px !important;
        font-weight: 600;
    }
}
@media screen and (min-width: 199px) and (max-width: 313px) {
    .wellcome-title{
        font-size: 27px;
    }
    .nkab-button-icons img{
        width: 120px;
    }
    
    .button-noselect {
        width: 121px;
        height: 70px;
        color: white;
        font-size: 12px !important;
    }
    .title, .card-title{
        font-size: 12px !important;
        font-weight: 600;
    }
}
@media screen and (min-width: 199px) and (max-width: 312px) {
    .wellcome-title{
        font-size: 27px;
    }
    .nkab-button-icons img{
        width: 120px;
    }
    
    .button-noselect {
        width: 121px;
        height: 70px;
        color: white;
        font-size: 12px !important;
    }
    .title, .card-title{
        font-size: 12px !important;
        font-weight: 600;
    }
}
@media screen and (min-width: 199px) and (max-width: 311px) {
    .wellcome-title{
        font-size: 27px;
    }
    .nkab-button-icons img{
        width: 120px;
    }
    
    .button-noselect {
        width: 121px;
        height: 70px;
        color: white;
        font-size: 12px !important;
    }
    .title, .card-title{
        font-size: 12px !important;
        font-weight: 600;
    }
}
@media screen and (min-width: 199px) and (max-width: 310px) {
    .wellcome-title{
        font-size: 27px;
    }
    .nkab-button-icons img{
        width: 120px;
    }
    
    .button-noselect {
        width: 119px;
        height: 70px;
        color: white;
        font-size: 12px !important;
    }
    .title, .card-title{
        font-size: 12px !important;
        font-weight: 600;
    }
}
@media screen and (min-width: 199px) and (max-width: 309px) {
    .wellcome-title{
        font-size: 27px;
    }
    .nkab-button-icons img{
        width: 120px;
    }
    
    .button-noselect {
        width: 119px;
        height: 70px;
        color: white;
        font-size: 12px !important;
    }
    .title, .card-title{
        font-size: 12px !important;
        font-weight: 600;
    }
}
@media screen and (min-width: 199px) and (max-width: 308px) {
    .wellcome-title{
        font-size: 27px;
    }
    .nkab-button-icons img{
        width: 120px;
    }
    
    .button-noselect {
        width: 119px;
        height: 70px;
        color: white;
        font-size: 12px !important;
    }
    .title, .card-title{
        font-size: 12px !important;
        font-weight: 600;
    }
}
@media screen and (min-width: 199px) and (max-width: 307px) {
    .wellcome-title{
        font-size: 27px;
    }
    .nkab-button-icons img{
        width: 120px;
    }
    
    .button-noselect {
        width: 119px;
        height: 70px;
        color: white;
        font-size: 12px !important;
    }
    .title, .card-title{
        font-size: 12px !important;
        font-weight: 600;
    }
}
@media screen and (min-width: 199px) and (max-width: 306px) {
    .wellcome-title{
        font-size: 27px;
    }
    .nkab-button-icons img{
        width: 120px;
    }
    
    .button-noselect {
        width: 118px;
        height: 70px;
        color: white;
        font-size: 12px !important;
    }
    .title, .card-title{
        font-size: 12px !important;
        font-weight: 600;
    }
}
@media screen and (min-width: 199px) and (max-width: 305px) {
    .wellcome-title{
        font-size: 27px;
    }
    .nkab-button-icons img{
        width: 120px;
    }
    
    .button-noselect {
        width: 117px;
        height: 70px;
        color: white;
        font-size: 12px !important;
    }
    .title, .card-title{
        font-size: 12px !important;
        font-weight: 600;
    }
}
@media screen and (min-width: 199px) and (max-width: 304px) {
    .wellcome-title{
        font-size: 27px;
    }
    .nkab-button-icons img{
        width: 120px;
    }
    
    .button-noselect {
        width: 117px;
        height: 70px;
        color: white;
        font-size: 12px !important;
    }
    .title, .card-title{
        font-size: 12px !important;
        font-weight: 600;
    }
}
@media screen and (min-width: 199px) and (max-width: 303px) {
    .wellcome-title{
        font-size: 27px;
    }
    .nkab-button-icons img{
        width: 120px;
    }
    
    .button-noselect {
        width: 115px;
        height: 70px;
        color: white;
        font-size: 12px !important;
    }
    .title, .card-title{
        font-size: 12px !important;
        font-weight: 600;
    }
}
@media screen and (min-width: 199px) and (max-width: 302px) {
    .wellcome-title{
        font-size: 27px;
    }
    .nkab-button-icons img{
        width: 120px;
    }
    
    .button-noselect {
        width: 115px;
        height: 70px;
        color: white;
        font-size: 12px !important;
    }
    .title, .card-title{
        font-size: 12px !important;
        font-weight: 600;
    }
}
@media screen and (min-width: 199px) and (max-width: 301px) {
    .wellcome-title{
        font-size: 27px;
    }
    .nkab-button-icons img{
        width: 120px;
    }
    
    .button-noselect {
        width: 115px;
        height: 70px;
        color: white;
        font-size: 12px !important;
    }
    .title, .card-title{
        font-size: 12px !important;
        font-weight: 600;
    }
}
@media screen and (min-width: 199px) and (max-width: 300px) {
    .wellcome-title{
        font-size: 27px;
    }
    .nkab-button-icons img{
        width: 139px;
    }
    
    .button-noselect {
        width: 100px;
        height: 60px;
        color: white;
        font-size: 10px !important;
    }
    .title, .card-title{
        font-size: 12px !important;
        font-weight: 600;
    }
}
