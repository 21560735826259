.nkab-profil-menus{
    border-bottom: 1px solid rgb(61, 60, 60);
}
.nkab-profil-menu-item{
    margin-left: 2px;
    margin-right: 2px;
    background-color: rgb(61, 60, 60);
    padding: 0px 5px 0px 5px;
    border-radius: 5px 5px 0px 0px;
}
.nkab-profil-menu-item:hover{
    background-color: rgba(104, 103, 103, 0.226);
    color: rgb(61, 60, 60);
    cursor: pointer;
    border-bottom: 2px solid rgb(61, 60, 60);
}
.nkab-profil-content-item{
    border-bottom: 0.5px solid rgba(104, 103, 103, 0.226);
}