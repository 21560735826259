/*STANDARD SESSION*/
.nkp_body {
	font-family: sans-serif;
    background-color: #ebebeb;
    height: 100%;
}
h1 {
	margin: 20px 0;
	color: #233f8e;
}
.nkp_main_title{
  font-weight: bolder;
  font-size: 35px !important;
  color: #233f8e;
}
.nkp_second_title{
  font-size: 20px !important;
  color: #78bee4;
  width: 50%;
}
.css-1v4ccyo{
  border-radius: 15px !important;
}
.nkp-info-services{
  font-size: 50px !important;
  color: #233f8e;
}
.nkp_center {
	text-align: center;
}
.nkab-premium{
  background-color: #233f8e;
  border: 2px solid #fdf0a5 ;
  padding: 0px 10px 0px 10px ;
  border-radius: 50px;
  color: #fdf0a5;
}
.nkab-premium:hover{
  background-color: #476cd1;
  border: 2px solid #2c2606 ;
  color: #2c2606;
}
.nkp_network_radio{
  padding-top: 13px;
}
.css-18iui6f-MuiInputBase-root-MuiOutlinedInput-root:focus{
  outline: none !important;
}
.nkp_network_radio label{
  color: #233f8e;
}
.nkp-alert-warning{
  color: #b8a432;
  background-color: #252314e1;
  border-radius: 5px;
}
.nkp-alert-info{
  color: #a5f0fd;
  background-color: #a5f0fd52;
  border-radius: 5px;
}
.nkp-alert-success{
  color: #a5fdac;
  background-color: #a5fdac52;
  border-radius: 5px;
}
.nkp-alert-danger{
  color: #861919;
  background-color: #fda5a552;
  border-radius: 5px;
}
.nkp_items_square-t{
  color: #676867 !important;
  background: #b8b8b82f !important;
  height: 53px !important;
  border-radius: 15px !important;
  border: 1px solid #69696965 !important;
}
.nkp_items_square{
  color: #676867 !important;
  background: #b8b8b82f !important;
  height: 53px !important;
  border-radius: 15px !important;
  border: 1px solid #0a146e !important;
}
.nkp_items_square:hover{
  height: 50px !important;
}
.nkp_eye{
  padding-top: 4px;
}
.nkp_items_list{
  z-index: 2;
}
.nkp_main_info{
  color: #78bee4;
}
.nkab-main-btn1{
  color: #fff;
  font-weight: 700;
  border-radius: 20px;
  padding: 5px 10px 5px 10px;
  width: 105px;
}
.nkab-main-btn1:hover{
  /* background: #59bd3879; */
  color: #c7b301;
  cursor: pointer;
}
.nkab-main-btn2{
  color: #fff;
  font-weight: 700;
  border-radius: 20px;
  padding: 5px 10px 5px 10px;
  width: 105px;
}
.nkab-main-btn2:hover{
  /* background: #d440407a; */
  color: #c7b301;
  cursor: pointer;
}
.nkab-main-btn3{
  color: #fff;
  font-weight: 700;
  border-radius: 20px;
  padding: 5px 10px 5px 10px;
  width: 105px;
}
.nkab-main-btn3:hover{
  /* background: #c7b30179; */
  color: #c7b301;
  cursor: pointer;
}
.nkp_position-absolute-t{
  background: #0a1929 !important;
  padding-top: 7px !important;
  padding-bottom: 7px !important;
  border-radius: 20px;
}
.nkp_position-absolute{
  background: #c4c6c7 !important;
  padding-top: 7px !important;
  padding-bottom: 7px !important;
  border-radius: 20px;
}
.select-scroll-container-t{
  overflow-y: auto;
  height: 195px;
}
.select-scroll-container{
  overflow-y: auto;
  height: 195px;
}
.nkp_item-position-t{
  color: #78bee4 !important;
}
.nkp_item-position-t:hover{
  color: #c7b301 !important;
  background: #8ad8ff21;
  border-radius: 20px;
}
.nkp_item-position{
  color: #0a146e !important;
}
.nkp_item-position:hover{
  color: #c7b301 !important;
  background: #0a146e;
  border-radius: 20px;
}
.logo-container {
	display: flex;
	justify-content: center;
	align-items: center;
}
.nkp_currency_content{
  padding: 10px;
}
.nkp_image{
    width: 50%;
    border-radius: 5px;
}
.op-pro-section{
  background-color: #4242462a;
}
.nkp_currency_price{
  color: #c7b301 !important;
  font-size: 13px;
}
.css-hip9hq-MuiPaper-root-MuiAppBar-root{
  background: none !important;
}
.nkp_right_image{
    width: 50%;
}
.nkp_right_image2{
    width: 80%;
}
.wellcome-title h1{
  font-size: 70px;
}
.nkp_currency-container{
  padding-top: 0.5rem;
  padding-bottom: 1rem;
  margin-top: 2rem;
  margin-bottom: 2rem;
  border-radius: 5px;

  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: space-between;
}
.nkp_logo {
	height: 40px;
  border-radius: 50px;
}
.nkp_button > a{
  color: #fff;
}
.nkp_button{
  background-color: #c7b301;
  border-radius: 5px;
	box-shadow: 5px 1px 10px rgba(0, 0, 0, 0.2);
  padding: 10px;
}
.nkp_button > a:hover{
  background-color: #c7b30154;
  color: #202020;
}
.nkp_info_panel{
  margin-top: 50px;
}

.nkp_menu-btn {
	cursor: pointer;
}

.nkp_hidden {
	display: none;
}

.form-check-input{
	font-size: 20px;
}
.nkp-eyes-see-more{
  color: #57575871;
}
.nkp-eyes-see-more:hover{
  color: #0A1929;
}
.nkab-cards-t{
  background: #0E233A;
  margin: 10px;
  padding: 20px;
  border-radius: 20px;
  /* height: 20vh; */
}
.nkab-cards{
  background: #5757583d;
  margin: 10px;
  padding: 20px;
  border-radius: 20px;
  /* height: 20vh; */
}
.nkab-cards:hover{
  box-shadow: 5px 5px 5px -1px rgba(5, 24, 85, 0.562);
}
.nkab-cards-t:hover{
  box-shadow: 5px 5px 5px -1px rgba(255, 254, 182, 0.192);
}
.nkab-cards2{
  background: #0e233a5b;
  margin: 10px;
  padding: 20px;
  border-radius: 20px;
}
.nkab-cards2{
  background: #41444718;
  margin: 10px;
  padding: 20px;
  border-radius: 20px;
}

/*Pro Story Table*/

.__pro-body-table {
  overflow: auto;
  max-height: 60vh;
  width: 100% !important;
}
@media (min-width: 48em) {
  .__pro-body-table {
    margin: 2%;
  }
}
@media (min-width: 75em) {
  .__pro-body-table {
    margin: 1em auto;
    max-width: 95em;
  }
}


/*NAV BAR*/
.nkp___nav {
  height: 59px;
  width: 100%;
  background-color: #233f8e;
  position: relative;
  z-index: 2;
}
.nkp___nav > .nkp___nav-header {
  display: inline;
}
.recharge-text, .buy-text{
  color: #00ca4e;
}
.transfert-text{
  color: #dacc07;
}
.newcode-text, .operation-text, .profile-text{
  color: #c4d4dd;
}
.nkp___nav > .nkp___nav-header > .nkp___nav-title {
  display: inline-block;
  font-size: 22px;
  color: #fff;
  padding: 10px 10px 10px 10px;
}

.nkp___nav > .nkp___nav-btn {
  display: none;
}
.nkp_power_btn{
    color: #f1c40f;
}
.nkp_power_btn:hover{
    color: #ca0404 !important;
}
.nkp___nav > .nkp___nav-links {
  display: inline;
  float: right;
  font-size: 18px;
}
.nkp__ul{
  margin-top: 1%;
}
.nkp___nav > .nkp___nav-links > ul > li {
  display: inline-block;
  padding: 9px 10px 13px 10px;
  text-decoration: none;
  font-size: smaller;
}
.nkp_Options{
  color: #87ccf1;
  border-bottom: #87ccf1 solid 2px;
}
.nkp___nav > .nkp___nav-links > ul > li:hover {
  color: #87ccf1;
  cursor: pointer;
}

.nkp___nav > #nkp___nav-check {
  display: none;
}

.nkp__version-modal{
  display: none;
}

@media (max-width:600px) {
  .nkp___nav > .nkp___nav-btn {
    display: inline-block;
    position: absolute;
    right: 0px;
    top: 0px;
  }
  .nkp___nav > .nkp___nav-btn > label {
    display: inline-block;
    width: 50px;
    height: 50px;
    padding: 13px;
    margin-top: 6px;
  }
  .nkp___nav > .nkp___nav-btn > label:hover,.nkp___nav  #nkp___nav-check:checked ~ .nkp___nav-btn > label {
    background-color: rgba(0, 0, 0, 0.3);
  }
  .nkp___nav > .nkp___nav-btn > label > span {
    display: block;
    width: 25px;
    height: 10px;
    border-top: 2px solid #eee;
  }
  .nkp___nav > .nkp___nav-links {
    position: absolute;
    display: block;
    width: 100%;
    background-color: #233f8e;
    height: 0px;
    transition: all 0.3s ease-in;
    overflow-y: hidden;
    top: 50px;
    left: 0px;
  }
  .nkp___nav > .nkp___nav-links > ul > li {
    display: block;
    width: 100%;
    color: #c7b301 !important;
  }
  .nkp___nav > .nkp___nav-links > ul > li:hover {
    display: block;
    width: 100%;
    color: #87ccf1 !important;
  }
  .nkp_power_btn:hover {
    color: #c21515 !important;
  }
  .nkp___nav > #nkp___nav-check:not(:checked) ~ .nkp___nav-links {
    height: 0px;
  }
  .nkp___nav > #nkp___nav-check:checked ~ .nkp___nav-links {
    height: calc(100vh - 50px);
    overflow-y: auto;
  }
  /*FAQ*/
  /* .nkp_faq_strong{
    font-size: 13px !important;
  } */
}


/*REACT SWITCH*/
.nkp__react-switch{
  width: 95px !important;
}
.react-switch-bg{
  height: 19px !important;
  width: 46px !important;
  background: #00b83df3 !important;
}
.react-switch-bg > div{
  display: none !important;
}
.react-switch-handle{
  height: 17px !important; 
  width: 17px !important; 
}



/*NKAB PREMIUM SUBSCRIBE*/
.nkab-subscribe-cards{
  flex-wrap: wrap;
  display: flex;
  justify-content: center;
}
.nkab-premium-period-title{
  font-size: 30px;
  font-weight: 700;
}
.nkab-premium-period-slash, .nkab-premium-period{
  font-size: 14px;
  color: gray;
}
.nkab-premium-period{
  font-weight: 700;
}



/*---------------------Conversion Rate List----------------------*/
.nkp_op_listing_conversion-rate-list {
  width: 80%;
  margin: 5px auto;
  text-align: center;
  list-style: none;
}

.nkp_op_listing_list-item {
  padding-bottom: 1em;
  border-radius: 6px;
  box-shadow: 1px 1px 16px var(--transparent-black);
  overflow: hidden;
}

.nkp_op_listing_list-item + .nkp_op_listing_list-item {
  margin-top: 10px;
}

.nkp_op_listing_platform {
  background: #233f8e;
  color: var(--white);
}

.nkp_op_listing_name,
.nkp_op_listing_platform {
  font-family: var(--title-font), sans-serif;
  font-size: 15px;
  font-weight: 500;
  line-height: 2;
}

.nkp_op_listing_value {
  line-height: 1.5;
  font-family: var(--data-font), sans-serif;
  font-size: 15px;
  color: var(--light-black);
}

/*-----------------------------Conversion Rate Table---------------------------*/
.nkp_op_listing_conversion-rate-table {
  flex-basis: min(80%, 900px);
  display: none;
  padding-bottom: 1em;
  background-color: var(--white-bone);
  border-radius: 6px;
  border-collapse: collapse;
  box-shadow: 1px 1px 16px var(--transparent-black);
  overflow: hidden;
}

.nkp_op_listing_table__headers {
  background-color: #233f8e;
}

.nkp_op_listing_header {
  padding: .25em 0;
  font-family: var(--title-font), sans-serif;
  font-size: 17px;
  font-weight: 500;
  color: var(--white);
}

.nkp_op_listing_table__row:nth-child(odd) {
  background-color: var(--white);
}

.nkp_op_listing_row__cell{
  padding: .25em 0;
  font-family: var(--data-font), sans-serif;
  font-size: 15px;
  text-align: center;
}

.nkp_op_listing_row__cell2{
  padding: .25em 0;
  font-family: var(--data-font), sans-serif;
  font-size: 15px;
  text-align: center;
  background-color: #424242;
  color: #ffffff;
}
.nkp_op_l_r_c2:hover{
  background-color: #c7b301;
  font-weight: bolder;
  cursor: pointer;
}

.nkp_op_listing_row__cell + .nkp_op_listing_row__cell,
.nkp_op_listing_header + .nkp_op_listing_header {
  border-left: 1px solid var(--light-gray);
}

@media (max-width: 500px) {
  .nkp_op_listing_conversion-rate-list {
    overflow-y: auto;
    height: 95vh;
  }
}

@media (min-width: 600px) {
  .nkp_op_listing_conversion-rate-list {
    display: none;
  }
  
  .nkp_op_listing_conversion-rate-table {
    display: table;
  }
  
  .nkp_op_listing_wrap {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 37vh;
  }
}

/*---------------------End Conversion Rate List----------------------*/

@media screen and (max-width: 800px) {

  /* FORM PRO */
  .nkp_container1, .nkp_container2, .nkp_container2_wave, .nkp_container2_vb, .nkp_container2_db, .nkp_container2_cash, .nkp_container3{
      width: 363px !important;
      /* margin-top: -20px !important; */
      border-radius: 15px !important;
      box-shadow: 5px 1px 10px rgba(0, 0, 0, 0.2);

  }
}



/* FORM PRO */
.nkp_container1, .nkp_container2, .nkp_container2_wave, .nkp_container2_vb, .nkp_container2_db, .nkp_container2_cash, .nkp_container3{
  width: 600px;
  margin: 20px auto;
  background: #ececec;
  border-radius: 15px;
  backdrop-filter: blur(5px);
  -webkit-backdrop-filter: blur(5px);
  padding: 5px;
  border: 2px solid #c7b301;
}
.nkp_input{
    display: block;
}
.nkp_items{
    width:90%;
    height: 50px;
    border-radius: 5px;
    font-size: 12px !important;
    /* background: aliceblue; */
    color: #233f8e;
}
.nkp_item_u{
    width:100%;
    background-color: #f6f8fa;
    margin-top: 1px;
    margin-bottom: 1px;
    border: #233f8e05 solid 0.5px;
    border-radius: 5px;
}
.nkp_item_u:hover{
  background: rgb(215, 234, 255);
}
/* .overflow_y::-webkit-scrollbar{
    width: 0 !important;
  } */
.overflow_y{
    height: 15vh;
    overflow-y: auto;
}
.nkp_radio_input{
  width:71%;
}
.nkp_price_input, .nkp_price_input2{
    width:70%;
}
.nkp_price_input2{
  background: #b8b8b82f !important;
}
.nkp_adress_input{
  width:100%;
}
.nkp_price_input, .nkp_adress_input, .nkp_radio_input{
    border-radius: 5px;
    font-size: 15px;
    background: #e2e2e22f;
    color: #676867;
}
.nkp_price, .nkp_caption{
    /* color: #676867 !important; */
    font-size: small;
}
.nkp_items:hover{
    cursor: pointer;
}
.nkp_item1{
    padding-top: 11px;
}
.nkp_item2, .nkp_item3{
    padding-top: 15px;
}
.nkp_input[type="text"]{
    width:100%;
    padding: 15px;
    border-radius: 5px;
    border: none;
    outline: none;
    font-size: 15px;
    background: #b8b8b82f;
    color: #676867;
}
.nkp_input[type="password"]{
    width:100%;
    padding: 15px;
    border-radius: 5px;
    border: none;
    outline: none;
    font-size: 15px;
    background: #b8b8b82f;
    color: #676867;
}
.nkp_bq_input[type="text"]{
    width:100%;
    padding: 15px;
    border-radius: 5px;
    border: none;
    outline: none;
    font-size: 15px;
    background: #b8b8b82f;
    color: #676867;
}
.nkp_bq_input[type="date"]{
    width:100%;
    padding: 15px;
    border-radius: 5px;
    border: none;
    outline: none;
    font-size: 15px;
    background: #b8b8b82f;
    color: #676867;
}
.nkp_bq_input[type="time"]{
    width:100%;
    padding: 15px;
    border-radius: 5px;
    border: none;
    outline: none;
    font-size: 15px;
    background: #b8b8b82f;
    color: #676867;
}
.nkp_bq_input[type="password"]{
    width:100%;
    padding: 15px;
    border-radius: 5px;
    border: none;
    outline: none;
    font-size: 15px;
    background: #b8b8b82f;
    color: #676867;
}
.nkp_cvv_input{
    width:20%;
}
.nkp_caption{ 
    width: 60% !important;
    text-align: center !important;
    margin: 5px auto 20px !important;
    font-size: 20px !important;
    font-weight: 600 !important;
    /* color: aliceblue !important; */
}
.nkp_bank_option_clicked{
	border: #c7b301 solid 2.5px !important;
	border-radius: 50px;
}
.nkp_bank_option:hover{
	border: #c7b301 solid 2.5px !important;
	border-radius: 50px;
}
.nkp_input[type="submit"]{
    padding: 10px;
    width: 35%;
    border-radius: 5px;
    border: none;
    /* box-shadow: 1px 1px 2px 1px rgba(0,0,0,0.4); */
    background: #c7b301;
    color: #fff;
    margin-top: 20px;
    font-size:15px;
}
.nkp_loader{
    padding: 10px;
    width: 35%;
    border-radius: 5px;
    border: none;
    /* box-shadow: 1px 1px 2px 1px rgba(0,0,0,0.4); */
    /* background: #c7b301; */
    color: #fff;
    margin-top: 20px;
    font-size:15px;
	font-weight: 600;
}
.nkp_popup_link-1{
    padding: 10px;
    width: 35%;
    border-radius: 50px;
    border: none;
    /* box-shadow: 1px 1px 2px 1px rgba(0,0,0,0.4); */
    background: #c7b301;
    color: #000;
    margin-top: 20px;
    font-size:15px;
	font-weight: 600;
}
.nkp_popup_link-2{
  padding: 10px;
  width: 35%;
  border-radius: 50px;
  border: none;
  /* box-shadow: 1px 1px 2px 1px rgba(0,0,0,0.4); */
  background: #c7b30125;
  color: #00000036;
  margin-top: 20px;
  font-size:15px;
	font-weight: 600;
}
.font-count-family{
  font-family: sans-serif;
  font-style: oblique;
  font-weight: 600;
  color: #494949;
}
.nkab-radio-label-t{
  color: #fff !important;
  font-weight: 700;
}
.nkab-radio-label-taux{
  color: #c7b301 !important;
  font-weight: 700;
}
.nkab-radio-label{
  color: #0a146e !important;
  font-weight: 700;
}
.controler_danger{
    border: rgb(255, 100, 100) solid 0.5px !important;
}

.controler_success{
    border: rgb(100, 255, 126) solid 0.5px !important;
}


/*NKP POPUP*/

#nkp_pin_popup { 
    position:fixed;
    visibility:hidden; 
    opacity:0; 
    margin-top:-300px; 
}

#nkp_pin_popup:target { 
    visibility:visible; 
    opacity:1; 
    background-color:rgba(255,255,255,0.7); 
    position:fixed; 
    top:0; 
    left:0; 
    right:0; 
    bottom:0; 
    margin:0; 
    z-index:99999999999; 
    -webkit-transition:all 1s; 
    -moz-transition:all 1s; 
    transition:all 1s; 
}
.nkp_pin_up-img{
    width: 400px;
}

.nkp_pin_popup-container {  
    margin:7% auto; 
    padding:30px 50px; 
}

.nkp_pin_popup-container2 {  
    margin:3% auto; 
    padding:30px 50px; 
}

.nkp_pin_popup-container, .nkp_pin_popup-container2 { 
    position:relative; 
    background-color:#ffffff;
    color:#fff; 
    border-radius:10px; 
	background: linear-gradient(to right bottom, #93b6cc, #233f8e);
	box-shadow: .4rem .4rem 2.4rem .2rem hsla(236, 50%, 50%, 0.3);
}

a.nkp_pin_popup-close { 
    position:absolute; 
    top:3px; 
    right:3px; 
    padding:7px 10px; 
    font-size:20px; 
    text-decoration:none; 
    line-height:1; 
    color:#fff; 
	border-radius: 5px;
}
.nkp_popup_btn{
    padding: 10px;
    width: 20%;
    border-radius: 5px;
    border: none;
    /* box-shadow: 1px 1px 2px 1px rgba(0,0,0,0.4); */
    background: #c7b301;
    color: #fff;
    margin-top: 20px;
    font-size:15px;
	font-weight: 600;
}
.nkp_popup_btn:hover{
    background: #fff;
    color: #c7b301;
	cursor: pointer;
}
  


/*Bottom navigation*/

#nkp_bottom_navbar {
    position: fixed;
    bottom: 0;
    width: 100%;
    flex-direction: row ;
    display: flex;
    z-index: 2;
    background: #ffffff;
    justify-content: center;
    align-items: center;
    border-radius: 10px 10px 0px 0px;
    box-shadow: 5px 1px 10px rgba(0, 0, 0, 0.2);
    transition: bottom 0.8s;
  }
  #nkp_bottom_navbar.visible {
    transform: translateY(0);
  }
  
  #nkp_bottom_navbar.hidden {
    transform: translateY(100%);
  }
  #nkp_bottom_navbar .nkab_ul {
    display: flex;
    width: 350px;
  }
  #nkp_bottom_navbar .nkab_ul .nkab_li{
    position: relative;
    width: 100%;
    height: 60px;
    z-index: 1;
  }
  #nkp_bottom_navbar .nkab_ul .nkab_li a{
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    text-align: center;
    width: 100%;
    font-size: 500;
  }
  #nkp_bottom_navbar .nkab_ul .nkab_li a .nkp_bottom_span{
    position: relative;
    display: block;
    line-height: 50px;
    font-size: 1.5em;
    text-align: center;
    transition: 0.5s;
  }
  #nkp_bottom_navbar .nkab_ul .nkab_li a .nkp_bottom_span:hover{
    font-size: 2em;
    transition: 0.5s;
  }
  /* #nkp_bottom_navbar .nkab_ul .nkab_li.active a .nkp_bottom_span{
    transform: translateY(-25px);
  } */
  .nkp_bottom_span{
    color: #4067ab;
    padding: 5px;
  }
  .color-or-nkab{
    color:#c7b301 !important;
  }
  .nkp_bottom_span:hover{
    color: #c7b301;
  }
  #nkp_bottom_navbar .nkab_ul .nkab_li a .nkp_bottom_navbar_title{
    position: absolute;
    color: #676867 !important;
    font-weight: 700;
    font-size: 0.80em;
    letter-spacing: 0.05em;
    transition: 0.5s;
    opacity: 0;
    transform: translateY(20px);
  }
  #nkp_bottom_navbar .nkab_ul .nkab_li.active a .nkp_bottom_navbar_title{
    opacity: 1;
    transform: translateY(20px);
  }
  .indicator{
    position: absolute;
    top: -50%;
    width: 70px;
    height: 70px;
    background-color: #233f8e;
    border-radius: 50%;
    border: 6px solid #ebebeb;
    transition: 0.5s;
  }
  /* .indicator::before{
    content: '';
    position: absolute;
    top: 43%;
    left: -22px;
    width: 20px;
    height: 20px;
    background-color: transparent;
    border-top-right-radius: 50px;
    box-shadow: 1px -10px 0 0 #ebebeb;
  }
  .indicator::after{
    content: '';
    position: absolute;
    top: 43%;
    right: -22px;
    width: 20px;
    height: 20px;
    background-color: transparent;
    border-top-left-radius: 50px;
    box-shadow: -1px -10px 0 0 #ebebeb;
  } */
  .active_color{
    color: #c7b301;
  }
  #nkp_bottom_navbar .nkab_ul .nkab_li:nth-child(1).active ~ .indicator{
    transform: translateX(calc(10px * 1));
  }
  #nkp_bottom_navbar .nkab_ul .nkab_li:nth-child(2).active ~ .indicator{
    transform: translateX(calc(96px * 1));
  }
  #nkp_bottom_navbar .nkab_ul .nkab_li:nth-child(3).active ~ .indicator{
    transform: translateX(calc(92px * 2));
  }
  #nkp_bottom_navbar .nkab_ul .nkab_li:nth-child(4).active ~ .indicator{
    transform: translateX(calc(90px * 3));
  }
  .nkp_bottom_navbar_icon{
    color: #857805;
  }
  .nkp_bottom_navbar_icon:hover {
    color: #2e2e2e;
  }
  div.nkp_left_content, div.nkp_right_content{
    text-align: center;
    word-wrap: break-word;
    padding-top: 30px;
  }
  div.nkp_left_content{
    padding-left: 35px;
    padding-right: 10px;
  }
  div.nkp_right_content{
    padding-right: 20px;
    padding-left: 15px;
  }
  #nkp_bottom_navbar a:hover {
    color: #2e2e2e;
    font-weight: 600;
  }

  .nkp_diplay_container{
      display: none;
  }


  /*INFO PANEL*/
  .nkp_card{
    padding: 10px;
    border-radius: 5px;
    width: 70%;
  }
  .nkp_card:hover{
    background-color: #c1d0da46;
	  box-shadow: 5px 1px 10px rgba(0, 0, 0, 0.2);
  }

  .nkp_modif_price-t{
    background: #15293f;
    padding-left: 5px;
    padding-right: 5px;
    padding-top: 2px;
    border-radius: 5px;
  }

.nkp_modif_price-t:hover{
  background: #1b3550;
  cursor: pointer;
  color: #c7b301;
}

.nkp_modif_price{
  background: #15293f23;
  padding-left: 5px;
  padding-right: 5px;
  padding-top: 2px;
  border-radius: 5px;
}

.nkp_modif_price:hover{
background: #1b3550;
cursor: pointer;
color: #c7b301;
}

  /*PROFIL*/
  .nkp_profil_center {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
  }
  .nkp_profil_btn, .nkp_status{
    background-color: #dbffe15d;
    padding: 3px;
  }
  .nkp_profil_btn a{
    color: #a5fcb3;
  }
  .nkp_profil_btn a:hover{
    cursor: pointer;
    background-color: #a5fcb3;
    color: #0A1929;
  }
  .nkp_profil_card {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    background-color: #fff;
    border-radius: 25px;
    padding: 2rem 3rem;
    box-shadow: 0px 10px 30px #00000020;
  }
  
  .nkp_profil_profile-container img {
    width: 90px;
    height: 90px;
    border-radius: 50%;
    object-fit: cover;
  }
  
  .nkp_profil_name-container {
    text-align: center;
    margin-top: 2rem;
  }
  
  .nkp_profil_posts-container,
  .nkp_profil_follower-container,
  .nkp_profil_following-container {
    text-align: center !important;
  }
  
  .nkp_profil_cta {
    display: flex;
    gap: 10px;
    justify-content: center;
    align-items: center;
    width: 100%;
  }
  
  .nkp_profil_cta a {
    text-decoration: none;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
    padding: 0px 2rem;
    margin-top: 3rem;
  }
  
  .nkp_profil_cta a:nth-child(1) {
    color: #fff;
    background-color: #121212;
    padding: 10px 2rem;
    transition: all .3s ease;
  }
  
  .nkp_profil_cta a:nth-child(2) {
    color: #000;
    border: 1px solid #a4a4a4;
    padding: 10px 2rem;
    transition: all .3s ease;
  }

  .nkp_border{
    border-radius: 5px !important;
  }

.image_selected{
  border: #00ca4e solid 1px;
  border-radius: 50px;
  width: 30px;
}

/*LOADING SESSION*/
@keyframes bouncing-loader {
  to {
    opacity: 0.1;
    transform: translate3d(0, -16px, 0);
  }
}

.bouncing-loader {
  display: flex;
  justify-content: center;
}

.bouncing-loader > div {
  width: 10px;
  height: 10px;
  /* margin: 3rem 0.2rem; */
  background: #c7b301;
  border-radius: 50%;
  animation: bouncing-loader 0.6s infinite alternate;
}

.bouncing-loader > div:nth-child(2) {
  animation-delay: 0.2s;
}

.bouncing-loader > div:nth-child(3) {
  animation-delay: 0.4s;
}



/*INFO BOTTOM*/
.nkp_currency2{
  display: none;
}

/*OP DIALOG POPUP*/
.MuiDialog-paper{
  background-color: #0E233A !important;
  border-radius: 30px !important;
  /* border-left: #c7b301 solid 10px !important; */
  /* border-right: #c7b301 solid 10px  !important; */
  border: 0.3px solid #fff;
}
.MuiDialogContentText-root{
  font-size: 19px !important;
}

/*FOOTER*/
.nkp_bg-footer {
  background-color: #233f8e !important;
  padding: 50px 0 30px;
}
.nkp_footer-heading {
  letter-spacing: 2px;
}

.nkp_footer-link a {
  color: #acacac;
  line-height: 40px;
  font-size: 14px;
  transition: all 0.5s;
}

.nkp_footer-link a:hover {
  color: #c7b301;
}
.nkab-popup-tex-color{
  color: #fff !important;
}
.nkp_contact-info {
  color: #acacac;
  font-size: 14px;
}
.nkab-choice-hover:hover{
  color: #fff387;
  background: #c7b3013f;
  padding-left: 3px;
  padding-right: 3px;
  border-radius: 20px;
}
.nkp_footer-social-icon {
  font-size: 15px;
  height: 34px;
  width: 34px;
  line-height: 34px;
  border-radius: 3px;
  text-align: center;
  display: inline-block;
}

.nkp_facebook {
  /* background-color: #4e71a8; */
  color: #ffffff;
}

.nkp_twitter {
  /* background-color: #55acee; */
  color: #ffffff;
}

.nkp_google {
  /* background-color: #d6492f; */
  color: #ffffff;
}

.nkp_apple {
  /* background-color: #424041; */
  color: #ffffff;
}

.nkp_footer-alt {
  color: #acacac;
}

.nkp_footer-heading {
  position: relative;
  padding-bottom: 12px;
}

.nkp_footer-heading:after {
  content: '';
  width: 25px;
  border-bottom: 1px solid #FFF;
  position: absolute;
  left: 0;
  bottom: 0;
  display: block;
  border-bottom: 1px solid #c7b301;
}

/*FAQ*/
.nkp_faq_title_hovered{
  color: #c7b301;
}
.nkp_faq_title-t{
  color: #87ccf1;
  padding: 5px;
}
.nkp_faq_title{
  color: #52514f;
  padding: 5px;
}
.nkp_faq_title-t:hover{
  color: #c7b301;
  background: #acacac38;
  border-radius: 10px;
}
.nkp_faq_title:hover{
  color: #c7b301;
  background: #acacac38;
  border-radius: 10px;
}
.nkp_p_content{
  text-align: left;
  background: #acacac38;
  padding: 10px;
  border-radius: 10px;
}
.nkab-beginning{
  margin-top: 25px !important;
  background: #acacac38;
  padding: 20px;
  border-radius: 20px;
}

/*NKP CALCULATOR*/
.nkp_calculator_main_card{
  background-color: #1212121a;
  padding: 20px;
  border-radius: 5px;
}
.nkp_calculator_card{
  background-color: #fff;
  border-radius: 5px;
  padding: 10px;
}
.nkp_calculator{
  border: #2e2e2e3f 1px solid;
  padding: 10px;
  border-radius: 5px;
}



/*ANIMATION CURRENCY*/

.waving {
  perspective: 1000px;
  -moz-perspective: 1000px;
}
.waving > * {
  display: inline-block;
  font-size: 5rem;
  font-weight: 700;
  text-transform: uppercase;
  color: #fff;
  transform-origin: top;
  animation: waving 2s infinite ease-in-out;
  animation-fill-mode: backwards;
}
.waving > *:nth-child(1) {
  animation-delay: 0.12s;
}
.waving > *:nth-child(2) {
  animation-delay: 0.24s;
}
.waving > *:nth-child(3) {
  animation-delay: 0.36s;
}
.waving > *:nth-child(4) {
  animation-delay: 0.48s;
}
.waving > *:nth-child(5) {
  animation-delay: 0.6s;
}
.waving > *:nth-child(6) {
  animation-delay: 0.72s;
}
.waving > *:nth-child(7) {
  animation-delay: 0.84s;
}
.waving > *:nth-child(8) {
  animation-delay: 0.96s;
}
.waving > *:nth-child(9) {
  animation-delay: 1.08s;
}
.waving > *:nth-child(10) {
  animation-delay: 1.2s;
}
.waving > *:nth-child(11) {
  animation-delay: 1.32s;
}
.waving > *:nth-child(12) {
  animation-delay: 1.44s;
}
.waving > *:nth-child(13) {
  animation-delay: 1.56s;
}
.waving > *:nth-child(14) {
  animation-delay: 1.68s;
}
.waving > *:nth-child(15) {
  animation-delay: 1.8s;
}
.waving > *:nth-child(16) {
  animation-delay: 1.92s;
}
.waving > *:nth-child(17) {
  animation-delay: 2.04s;
}
.waving > *:nth-child(18) {
  animation-delay: 2.16s;
}
.waving > *:nth-child(19) {
  animation-delay: 2.28s;
}
.waving > *:nth-child(20) {
  animation-delay: 2.4s;
}

@keyframes waving {
  0% {
    transform: rotateX(30deg) translateY(0);
    text-shadow: 0px 3px 0px #ccc, 0px 10px 10px rgba(0, 0, 0, 0.5);
  }
  50% {
    transform: rotateX(45deg) translateY(-20px);
    text-shadow: 0px 3px 0px #ccc, 0px 55px 20px rgba(0, 0, 0, 0.4);
  }
  100% {
    transform: rotateX(30deg) translateY(0);
    text-shadow: 0px 3px 0px #ccc, 0px 10px 10px rgba(0, 0, 0, 0.5);
  }
}



/*CURRENCY POPUP*/
.nkp__box {
  width: 40%;
  margin: 0 auto;
  background: rgba(255,255,255,0.2);
  padding: 35px;
  border: 2px solid #fff;
  border-radius: 20px/50px;
  background-clip: padding-box;
  text-align: center;
}

.nkp__overlay {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.7);
  transition: opacity 500ms;
  visibility: hidden;
  opacity: 0;
}
.nkp__overlay:target {
  visibility: visible;
  opacity: 1;
}

.nkp__popup {
  margin: 70px auto;
  padding: 20px;
  background: #fff;
  border-radius: 5px;
  width: 30%;
  position: relative;
  transition: all 5s ease-in-out;
}

.nkp__popup h2 {
  margin-top: 0;
  color: #333;
  font-family: sans-serif;
}
.nkp__popup .nkp__close {
  position: absolute;
  top: 7px;
  right: 30px;
  transition: all 200ms;
  font-size: 30px;
  text-decoration: none;
  color: #333;
}
.nkp__popup .nkp__close:hover {
  color: #c7b301;
}
.nkp__currency_list{
  max-height: 400px;
  overflow: auto;
}
.nkp_currency_items{
  text-align: left;
}
.nkp_currency_item{
  padding: 5px;
  border-radius: 5px;
  cursor: pointer;
}
.nkp_currency_item:hover{
  background-color: #2e2e2e18;
}

.nkp_currency_item_container{
  text-decoration: none;
}

@media screen and (max-width: 700px){
  .nkp__box{
    width: 70%;
  }
  .nkp__popup{
    width: 70%;
  }
}



/*CAROUSEL PARTNER*/
.nkp__partner_marquee-full-width {
	width: 100vw;
	position: relative;
	left: 50%;
	right: 50%;
	margin-left: -50vw;
	margin-right: -50vw;
}
.nkp__partner_marquee-box {
	overflow-x: hidden !important;
	display: flex !important;
	flex-direction: row !important;
	position: relative;
	width: 100%;
}
.nkp__partner_marquee {
	flex: 0 0 auto;
	min-width: 100%;
	z-index: 1;
	display: flex;
	flex-direction: row;
	align-items: center;
	animation: marqueescroll 100s linear 0s infinite;
	animation-play-state: running;
	animation-delay: 0s;
	animation-direction: normal;
}
.nkp__partner_marquee .nkp__partner_figure
{
	margin-right: 3rem !important;
	margin-left: 3rem !important;
	padding: 5px !important;
	max-width: 250px !important;	
	text-align: center !important;
	vertical-align: top !important;
}
.nkp__partner_marquee .nkp__partner_figcaption
{
	display:none !important;
	font-weight: 80% !important;
	color: #333 !important;
	line-height: 1.2 !important;
}
.nkp__partner_marquee img
{
	margin: 0 auto;
	height: auto;
	max-height: 3.5rem;
	max-width: 100%;
	background: #fdfdfd;
	border: none;
}
.nkp__partner_marquee-box:hover div {
	animation-play-state: running;
}
.nkp__partner_marquee-box:active div {
	animation-play-state: running;
}
@keyframes marqueescroll {
	0% {
		transform: translateX(0%);
	}
	100% {
		transform: translateX(-100%);
	}
}

/*LOADING TRAITEMENT*/
.css-lzr3bi-LoadingOverlayWrapperBase{
  background-color: rgba(255, 255, 255, 0) !important;
  height: 62% !important;
}
._loading_overlay_overlay{
  border-radius: 20px;
}
._loading_overlay_content{
  color: #87ccf1 !important;
}




/*VALIDATION MODALE*/

@supports ((-webkit-animation: grow 0.5s cubic-bezier(0.25, 0.25, 0.25, 1) forwards) or (animation: grow 0.5s cubic-bezier(0.25, 0.25, 0.25, 1) forwards)) {
  .tick {
    stroke-opacity: 0;
    stroke-dasharray: 29px;
    stroke-dashoffset: 29px;
    -webkit-animation: draw 0.5s cubic-bezier(0.25, 0.25, 0.25, 1) forwards;
            animation: draw 0.5s cubic-bezier(0.25, 0.25, 0.25, 1) forwards;
    -webkit-animation-delay: 0.6s;
            animation-delay: 0.6s;
  }

  .circle {
    fill-opacity: 0;
    stroke: #219a00;
    stroke-width: 16px;
    transform-origin: center;
    transform: scale(0);
    -webkit-animation: grow 1s cubic-bezier(0.25, 0.25, 0.25, 1.25) forwards;
            animation: grow 1s cubic-bezier(0.25, 0.25, 0.25, 1.25) forwards;
  }

  .circle2 {
    fill-opacity: 0;
    stroke: #e75d5d;
    stroke-width: 16px;
    transform-origin: center;
    transform: scale(0);
    -webkit-animation: grow 1s cubic-bezier(0.25, 0.25, 0.25, 1.25) forwards;
            animation: grow 1s cubic-bezier(0.25, 0.25, 0.25, 1.25) forwards;
  }
}
@-webkit-keyframes grow {
  60% {
    transform: scale(0.8);
    stroke-width: 4px;
    fill-opacity: 0;
  }
  100% {
    transform: scale(0.9);
    stroke-width: 8px;
    fill-opacity: 1;
    fill: #219a00;
  }
}
@keyframes grow {
  60% {
    transform: scale(0.8);
    stroke-width: 4px;
    fill-opacity: 0;
  }
  100% {
    transform: scale(0.9);
    stroke-width: 8px;
    fill-opacity: 1;
    fill: #219a00;
  }
}
@-webkit-keyframes draw {
  0%, 100% {
    stroke-opacity: 1;
  }
  100% {
    stroke-dashoffset: 0;
  }
}
@keyframes draw {
  0%, 100% {
    stroke-opacity: 1;
  }
  100% {
    stroke-dashoffset: 0;
  }
}



/*MOBIL SESSION*/

@media (min-width:400px) { 
    .nkp_pin_popup-container, .nkp_pin_popup-container2 { width:600px; } 
    .nkp_pin_up-img{ width: 250px; }
    /* .nkp_faq_strong{ font-size: 14px !important; } */

}

@media (min-width:768px) { 
    .nkp_pin_popup-container, .nkp_pin_popup-container2 { width:600px; } 
    .nkp_pin_up-img{ width: 250px; }
    /* .nkp_faq_strong{ font-size: 14px !important; } */

}

@media (max-width:767px) { 
    .nkp_pin_popup-container, .nkp_pin_popup-container2 { width:86%; } 
    .nkp_pin_up-img{ width: 250px; }
    div.nkp_left_content, div.nkp_right_content, .title_exployd, .nkp_info_sub_text, .nkp_calculator_title, .nkp_faq_mobile_title, .nkp_second_title p, .nkp__version-modal2, .nkp__version-modal_lite, .gest-svg-frame, .home-crypto-section, .phone-item, .nkab-com, .popular-currency, .nkab-pro-home-img, .nkab-estimated-account, .nkab-explor-section{
        display: none !important;
    }
    .accueil-main_container {
      height: 65vh;
    }
    .about-main_container {
      height: 75vh;
    }
    .contact-main_container {
      height: 65vh;
    }
    .wellcome-title h1{
        font-size: 25px;
    }
    .home-currency-img, .login-currency-img{
        text-align: center;
        width: 70% !important;
    }
    .css-binzgt{
      margin-top: 0 !important;
    }
    .lite-currency-item{
      font-size: 12px !important;
    }
    .waving > * {
      font-size: 40px;
    }
    .nkp_main_title{
      font-size: 20px !important;
    }
    .nkp_calculator_container{
      padding-top: 30px;
    }
    .nkp_title_h1{
      font-size: 12px;
    }
    .nkp_diplay_container{
        display: block !important;
    }
    .nkp_buy_title{
      font-size: 12px;
    }
    .change-name > span{
      font-size: 10px;
    }
    .change-titles{
      padding: 5px;
    }
    .nkp_currency_content{
      padding: 5px !important;
    }
    .nkp-info-services{
      font-size: 25px !important;
    }
    /*FAQ*/
    /* .nkp_faq_strong{
      font-size: 13px !important;
    } */
    .nkp__version-modal,
    .nkab-theme-mobile{
      display: inline;
    }
    .nkp_caption{ 
        font-size: 17px !important;
    }
    .nkp_text{
      font-size: 10px !important;
    }
    .css-lzr3bi-LoadingOverlayWrapperBase{
      background-color: rgba(255, 255, 255, 0) !important;
      height: 66% !important;
    }
    .header__title{
      font-size: 15px;
    }
    .wellcome-title{
      width: 100%;
    }
    .wellcome-title h1{
      font-size: 32px;
    }
    footer a{
      font-size: 12px;
    }
    .nkp_profil_card {
      width: 94%;
    }
}
  