.stat-card{
    background-color: #d0efff;
    margin: 5px;
    padding: 5px;
    border-radius: 20px;
    box-shadow: 0px 1px 15px 10px rgba(121, 120, 120, 0.116);
    height: 8rem;
}
.stat-card-second{
    background-color: #d0efff;
    margin: 5px;
    padding: 5px;
    border-radius: 20px;
    box-shadow: 0px 1px 15px 10px rgba(121, 120, 120, 0.116);
}
.stat-title{
    font-size: 15px;
    font-weight: 600;
    background-color: #b3e6ff;
    color: #0a146e;
    border-radius: 20px;
}
.stat-see-card-detail-bnt{
    background-color: #0a146e1a;
    font-size: 12px;
    padding: 0px 10px 0px 10px;
    border-radius: 50px;
    color: #0a146e85;
}
.stat-see-card-detail{
    background-color: #0a146e56;
    font-size: 12px;
    padding: 0px 10px 0px 10px;
    border-radius: 50px;
    color: #d0efff;
}
.stat-see-card-detail:hover{
    background-color: #0a146e;
    cursor: pointer;
}
.stat-see-card-detail2{
    background-color: #0a146e !important;
    cursor: pointer;
}
.stat-see-card-detail-achat{
    background-color: #59bd38;
    font-size: 12px;
    padding: 0px 10px 0px 10px;
    border-radius: 50px;
    color: #d0efff;
}
.stat-see-card-detail-achat:hover{
    background-color: rgb(54, 160, 77);
    cursor: pointer;
}
.stat-see-card-detail-vente{
    background-color: #d44040;
    font-size: 12px;
    padding: 0px 10px 0px 10px;
    border-radius: 50px;
    color: #d0efff;
}
.stat-see-card-detail-vente:hover{
    background-color: #d46c6c;
    cursor: pointer;
}
.stat-card-title{
    font-size: 12px;
    font-weight: 600;
}
.stat-card-content{
    font-size: 25px;
    font-weight: 600;
}
.stat-sub-title{
    font-size: 12px;
}
.stat-content-title{
    background-color: #8ad8ff;
    padding: 5px;
    border-radius: 5px;
}
.nkab-stat-see{
    color: #000000;
    padding:'0px 5px 0px 5px';
}
.nkab-stat-see:hover{
    color: #c7b301;
}
.border-b{
    border-bottom: 0.1px solid #a3a3a33a;
}
.stat-total-tarif{
    background-color: #0a146e;
    padding: 0px 10px 0px 10px;
    border-radius: 50px;
    font-weight: 500;
    color: #ffffff;
}
.stat-total-tarif:hover{
    background-color: #1224c9;
}
.stat-rate-total-tarif{
    background-color: #c7b301;
    padding: 0px 10px 0px 10px;
    border-radius: 50px;
    font-weight: 500;
    color: #ffffff;
}
.stat-rate-total-tarif:hover{
    background-color: #ecae50;
}
.stat-marge-tarif{
    background-color: #04bb2ca2;
    padding: 0px 10px 0px 10px;
    border-radius: 50px;
    font-weight: 500;
    color: #ffffff;
}
.stat-marge-tarif:hover{
    background-color: #026818;
}
.stat-btn-see-more{
    color: #0a146e;
    background-color: #6969694b;
    padding: 0px 10px 0px 10px;
    border-radius: 50px;
}
.stat-btn-see-more:hover{
    cursor: pointer;
    background-color: #0a146e;
    color: #ffffff;
}
.stat-item{
    border-radius: 5px;
    padding: 3px;
}
.stat-item:hover{
    background-color: #8ad8ff;
}
.nkab-date-picker{
    display: 'flex';
    flex-wrap: 'wrap';
}
.nkab-date-text-field{
    margin-left: space(1);
    margin-right: space(1);
    width: 200px;
}
.nkab-schedule-send-icon-btn{
    background-color: #0a146e56;
    border-radius: 20px;
    padding-left: 20px;
    padding-right: 20px;
    padding-top: 1px;
    padding-bottom: 3px;
    box-shadow: 0px 1px 5px 1px rgba(0, 0, 0, 0.253);
}
.nkab-schedule-send-icon-btn:hover{
    background-color: #0a146e;
}
.nkab-schedule-send-icon{
    font-size: 16px !important;
}
.nkab-schedule-send-icon:hover{
    color: #c7b301;
}
.pointer-details:hover{
    cursor: pointer;
    color: #c7b301;
}
@media (max-width:767px) {
    .css-9ddj71-MuiInputBase-root-MuiOutlinedInput-root, .css-1v4ccyo{
        width: 100% !important;
    }
 }