
.focus-button-wrapper{
    margin-top: 18px;
  }
  
  .focus-btn {
    border: none;
    padding: 12px 24px;
    border-radius: 24px;
    font-size: 12px;
    font-size: 0.8rem;  
    letter-spacing: 2px;  
    cursor: pointer;
  }
  
  .focus-btn + .focus-btn {
    margin-left: 10px;
  }
  
  .focus-outline {
    background: transparent;
    color: #0E233A;
    border: 1px solid #0E233A;
    transition: all .3s ease;
    
  }
  
  .focus-outline:hover{
    transform: scale(1.125);
    color: rgba(255, 255, 255, 0.9);
    border-color: rgba(255, 255, 255, 0.9);
    transition: all .3s ease;  
  }
  
  .focus-fill {
    background: #0E233A;
    color: rgba(255,255,255,0.95);
    filter: drop-shadow(0);
    font-weight: bold;
    transition: all .3s ease; 
  }
  
  .focus-fill:hover{
    transform: scale(1.125);  
    border-color: rgba(255, 255, 255, 0.9);
    filter: drop-shadow(0 10px 5px rgba(0,0,0,0.125));
    transition: all .3s ease;    
  }