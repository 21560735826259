  .about-img {
    width: auto;
    height: 50px;
  }
  
  .about-main_container {
    height: 50vh;
    color: #fff;
    background: url('https://nkab-exchange.net/images/acceuil1.jpg') no-repeat ;
    background-size: cover; /* Adjust background size */
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .contact-main_container {
    height: 40vh;
    color: #fff;
    background: url('https://nkab-exchange.net/images/acceuil1.jpg') no-repeat ;
    background-size: cover; /* Adjust background size */
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .about-content {
    /* font-size: 17px; */
    text-align: center;
  }
  
  /* button {
    height: 55px;
    width: 170px;
    border-radius: 10px;
    border: 2px solid white;
    font-size: 20px;
    font-weight: 500;
    margin:8px;
    cursor: pointer;
    outline: none;
    transition: all 0.3s ease;
  } */