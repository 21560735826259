

.my_dom_main-item {
    padding: 1px;
    background-color: #fff;
    width: 100px;
  }
  
  .my_dom_background-masker {
    background-color: #fff;
    position: absolute;
  }
  
  .my_dom_btn-divide-left {
    top: 0;
    left: 25%;
    height: 100%;
    width: 5%;
  }
  
  @keyframes placeHolderShimmer {
    0% {
      background-position: -800px 0
    }
    100% {
      background-position: 800px 0
    }
  }
  
  .my_dom_animated-background {
    animation-duration: 2s;
    animation-fill-mode: forwards;
    animation-iteration-count: infinite;
    animation-name: placeHolderShimmer;
    animation-timing-function: linear;
    background: linear-gradient(to right, #eeeeee 8%, #b6e5ff 18%, #eeeeee 33%);
    background-size: 800px 104px;
    height: 10px;
    position: relative;
    border-radius: 5px;
  }
  
  
  