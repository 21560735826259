.errMessage{
    background: rgb(247, 173, 173);
    color: rgb(197, 17, 17);
    text-align: center;
    padding: 10px 10px;
    letter-spacing: 1.3px;
    border-radius: 3px;
}
.successMessage{
    background: rgb(159, 255, 175);
    color: rgb(16, 104, 23);
    text-align: center;
    padding: 10px 10px;
    letter-spacing: 1.3px;
    border-radius: 3px;
}