.nkab-slider-container {
    position: relative;
    width: 500px;
    height: 320px;
    overflow: hidden;
    margin: auto;
  }
  
  .nkab-slider-image {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    border-radius: 20px;
    transition: opacity 1s ease-in-out;
  }