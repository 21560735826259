.comment_root {
    background-color: #dbdbdb33;
    display: flex;
    flex-direction: column;
    padding: 5px;
    border-radius: 20px 10px 0px 0px;
  }

  .comment_root-t {
    background-color: #0E233A !important;
  }

.comment_entry {
    display: flex;
    padding: 8px;
    align-items: center;
    height: 58px;
}

.nkab-comments-content-items{
  background-color: #dbdbdb33;
  padding: 5px;
  margin: 5px 5px auto;
  border-radius: 20px;
  box-shadow: 0px 1px 15px 10px rgba(121, 120, 120, 0.116);
  border: 0.1px solid rgba(0, 0, 0, 0.062);
}

.nkab-comments-content-items-t{
  background-color: #0E233A !important;
}

#comment_msg {
    transition: all 0.3s;
    flex: 1;
    margin-right: 10px;
    border-radius: 8px;
    border: none;
    resize: none;
    padding: 4px 6px;
    border: 2px solid white;
    color: gray;
    height: min-content;
    background-color: rgb(196, 199, 202);
  }
  
  #comment_msg:focus {
    border: 2px solid gray;
    outline: none;
    color: black;
  }

  .comment_send {
    height: 46px;
    width: 46px;
    border:none;
    border-radius: 20px;
    transition:all 0.3s;
    font-size: 16px;
    cursor:pointer;
  }
  
  .comment_send:hover{
    color: #0a146e;
    background-color: white;
  }
  .nkab-witness-asker{
    font-size: 11px;
    padding: 0px 3px 0px 3px;
    border-radius: 50px;
    color: #c7b301;
  }
  .nkab-witness-asker:hover{
    background-color: #dbdbdb33;
    cursor: pointer;
  }